import React, { useState } from "react";

function ToggleButton() {
  const [toggle, setToggle] = useState(true);

  return (
    <div className="toggleButtons">
      <button
        className={`${!toggle ? "active" : ""}`}
        style={!toggle ? { background: "#702632" } : { background: "#8F8F8F" }}
        onClick={() => setToggle(false)}
      ></button>
      <button
        className={`${toggle ? "active" : ""}`}
        style={toggle ? { background: "#04681a" } : { background: "#8F8F8F" }}
        onClick={() => setToggle(true)}
      ></button>
    </div>
  );
}

export default ToggleButton;
