import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import './UserName.scss';
import xIcon from '../../../assets/xIcon.png';

const API = process.env.REACT_APP_API_URL

export default function SellTokens(props) {
  const { open , lease , closeSalePopUp, user_id, resetTheClock, accessToken} = props;
  //console.log(lease)
  const [communityCount, setCount] = useState()
  const [instantCount, setCount2] = useState()
  const [output, setOutput] = useState()
  const [output2, setOutput2] = useState()
  const [fees, setFees] = useState()
  
  function instantSale() {
      if (instantCount == undefined || instantCount == 0) {window.alert('no')}
      else {
      fetch(`${API}/sale`, {
          method: 'POST',
          mode: 'cors',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify({ count:instantCount, lease_id: lease.lease_id, user_id })
      })
          .then(res => window.alert(`${instantCount} tokens sold, the money has been added to their wallet`))
          .then(itstime => {
            window.location.reload()
              closeSalePopUp()
          })
  }}

  function communitySale() {
      if (communityCount == undefined || communityCount == 0) {window.alert('no')}
      else {
      fetch(`${API}/putUpForSaleHistory`, {
          method: 'POST',
          mode: 'cors',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify({ count : communityCount, lease_id: lease.lease_id, user_id })
      })
      .then(response => response.json())
      // .then(doit => {
      fetch(`${API}/putUpForSale`, {
          method: 'POST',
          mode: 'cors',
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify({ communityCount, lease_id: lease.lease_id, user_id })
      })
  //})
          .then(res => window.alert(`${communityCount} tokens put up for sale, they will be paid when someone makes a purchase`))
          .then(itstime => {
            window.location.reload()
              closeSalePopUp()
          })
  }}

  const onChange = key => (e) => {
      const re = /^[0-9\b]+$/;
      let d = isNaN(e.target.value) ? 1 : e.target.value
      if (d === '' || re.test(d)) {
          if (d <= lease.count) {
          setCount(d)
          setOutput((d * lease.token_price/100).toFixed(2))
          }
          else {
              window.alert(`They only have ${lease.count} tokens of this car, and ${lease.count} available to sell`)
          }
      }
  }

  const onChange2 = key => (e) => {
      const re = /^[0-9\b]+$/;
      let d = isNaN(e.target.value) ? 1 : e.target.value
      if (d === '' || re.test(d)) {
          if (d <= lease.count) {
          setCount2(d)
          setOutput2((d * (lease.token_price-78)/100).toFixed(2))
          setFees(d * 78)
      }
      else {
          window.alert(`They only have ${lease.count} tokens of this car, and ${lease.count} available to sell`)
      }
      }
  }

  return (
        <div className="sellTokenContent">
        { lease && <Dialog
                open={open}
                onClose={closeSalePopUp}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onBackdropClick={closeSalePopUp}
            >
                <div className="sellTokenContentPop">
                    <div className="sellTokenTitle">
                        <div className="title">Sell LeaseTokens</div>
                        <button className="close" onClick={closeSalePopUp}>
                            <img src={xIcon} alt=""></img>
                        </button>
                    </div>
                    <hr style={{margin: '20px 0px', height: '2px', opacity: '0.5'}} />
                    <div className="content">
                        <div className="communitySell">
                            <div className="title">
                                Community Sell
                                <hr style={{margin: '20px 0px', height: '2px', opacity: '0.5'}}/>
                            </div>
                            <div className="text">
                                <p>{'>'} No fees for selling token</p>
                                <p>{'>'} Continue to earn returns while the token is listed for sale</p>
                                <hr style={{margin: '20px 0px', height: '2px', opacity: '0.5'}}/>
                            </div>
                            <div className="tokenAmount">
                                <p>Token: <b>$ {(lease.token_price/100).toFixed(2)}</b></p>
                                <p>Fees: <b>$ 0.00</b></p>
                                <p>Total: <b>$ {output}</b></p>
                            </div>
                            <div className="buttons">
                                <input onChange={onChange()} placeholder="Quantity" value={communityCount} className="quantity"/>
                                <button className="instantSale" onClick={communitySale}>Community Sale</button>
                            </div>
                        </div>
                        <div className="communitySell">
                            <div className="title">
                            Instant Sell
                            <hr style={{margin: '20px 0px', height: '2px', opacity: '0.5'}}/>
                            </div>
                            <div className="text">
                                <p>{'>'} Instantly sell your token</p>
                                <p>{'>'} One month’s earnings of that token will be charged as a fee</p>
                                <hr style={{margin: '20px 0px', height: '2px', opacity: '0.5'}}/>
                            </div>
                            <div className="tokenAmount">
                                <p>Token: <b>$ {(lease.token_price/100).toFixed(2)}</b></p>
                                <p>Fees: <b>{fees && fees + '￠'}</b></p>
                                <p>Token: <b>$ {output2}</b></p>
                            </div>
                            <div className="buttons">
                                <input onChange={onChange2()} placeholder="Quantity" value={instantCount} className="quantity"/>
                                <button className="instantSale" onClick={instantSale}>Instant Sale</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>}
            </div>
        );
}