import React, {useState} from "react";
import {Link} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ActiveCoreTab from "./ActiveCoreTab/ActiveCoreTab";
import { useAuth0 } from "../react-auth0-spa";

//For ActiveCoreTab
import './ActiveCoreTab/ActiveCoreTab.scss';
import '../App.scss';
import arrowTab from '../assets/arrowTab.png';

function Container(props) {
  const { isAuthenticated } = useAuth0();
  const { children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const TopTabMobile = () => (
    <div className="topTabMobile">
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}>
                <div className="arrowButton">
                    <img src={arrowTab} alt=""></img>
                </div>
        </IconButton>
        <div className="activeCoreTitle">
            <Link to="/">
                <h2>ActiveCore</h2>
            </Link>
        </div>
    </div>
    )


    return (
        <div className="mainDashboard">

            {/* For Mobile Sidebar */}
            <CssBaseline />
            {isAuthenticated && 
            <AppBar
                position="fixed"
                sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                }}>
                <TopTabMobile />
            </AppBar>
            }

            {/* For Web View Sidebar */}
            {isAuthenticated &&
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }} >
                    <ActiveCoreTab />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open >
                    <ActiveCoreTab />
                </Drawer>
            </Box>
            }

            {/* Components */}
            {isAuthenticated && 
                <main className="content">
                    {children}
                </main>
            }     
        </div>
  );
};

export default Container;