//Importing components
import Operations from './components/Operations/Users';
import User from './components/Operations/User/User';
import Dealers from './components/Dealers/Dealers';
import Dealership from './components/Dealers/Dealership/Dealership';
import Deals from './components/Dealers/Deals/Deals';
import Platform from './components/Platform/Platform';
import UserName from './components/Platform/UserName/UserName';
import EditAsset from './components/Platform/EditAsset/EditAsset';
import Interfaces from './components/Interfaces/Controls';
import Notifications from './components/Notifications/Notifications';

//For Routing
import { Router, Switch} from "react-router-dom";
import PrivateRoute from './layout/PrivateRoute';
import history from './utils/history';
import ScrollToTop from './scrollToTop';
import { UserContextProvider } from './Context/UserContext';

//CSS Stuff
import './App.scss';
import AddDealer from './components/Dealers/AddDealer/AddDealer';

function App() {
  return (
    <div >
       <UserContextProvider>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <PrivateRoute path="/operations" component={Operations } />
          <PrivateRoute path="/User" component={User } />
          <PrivateRoute path="/Dealers" component={Dealers } />
          <PrivateRoute path="/AddDealer" component={AddDealer } />
          <PrivateRoute path="/Dealership" component={Dealership } />
          <PrivateRoute path="/Deals" component={Deals } />
          <PrivateRoute path="/Platform" component={Platform } />
          <PrivateRoute path="/UserName" component={UserName } />
          <PrivateRoute path="/EditAsset" component={EditAsset } />
          <PrivateRoute path="/interfaces" component={Interfaces } />
          <PrivateRoute path="/notifications" component={Notifications } />
          <PrivateRoute path="/" component={Operations } />
        </Switch>
      </Router>
      </UserContextProvider>
    </div>
  );
}

export default App;
