import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Platform.scss";
import moment from "moment";
import settingIcon from "../../assets/settingIcon.png";
import xIcon from "../../assets/xIcon.png";
import { useUserInfo, useUserInfoUpdate } from "../../Context/UserContext";

function Platform() {
  const context = useUserInfo();
  const [platformUsers, setPlatformUsers] = useState();
  const [assets, setAssets] = useState();
  const [all, setAll] = useState();
  const [filteredCard, updateFilteredCard] = useState("Users");
  const [search, setSearch] = useState("");

  useEffect(() => {
    let sortedUserList;
    context.userInfo
      ? (sortedUserList = context.userInfo.users.sort((a, b) =>
          a.last_name.localeCompare(b.last_name)
        ))
      : console.log("");
    let sortedAssetList;
    context.userInfo
      ? (sortedAssetList = context.userInfo.assets.sort(function (a, b) {
          return (
            Math.round(moment().diff(moment(a.start_date), "months", true)) -
            Math.round(moment().diff(moment(b.start_date), "months", true))
          );
        }))
      : console.log("");
    context.userInfo &&
      context.userInfo.users &&
      setPlatformUsers(sortedUserList);
    context.userInfo && context.userInfo.assets && setAssets(sortedAssetList);
    platformUsers && assets && setAll(platformUsers.concat(assets));
    // console.log("users", context.userInfo.users);
  }, [context.userInfo]);

  const handleChange = (event) => {
    setSearch(event.target.value);
    let sortedList = context.userInfo.users.sort((a, b) =>
      a.last_name.localeCompare(b.last_name)
    );
    let newSortedList = sortedList.filter(
      (l) =>
        (l.first_name + l.last_name)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        l.email.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.phone.toLowerCase().includes(event.target.value.toLowerCase())
    );
    let sortedList2 = context.userInfo.assets.sort(function (a, b) {
      return (
        Math.round(moment().diff(moment(a.start_date), "months", true)) -
        Math.round(moment().diff(moment(b.start_date), "months", true))
      );
    });
    let newSortedList2 = sortedList2.filter((l) =>
      (l.year + l.make + l.model)
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    if (filteredCard == "Users") {
      setPlatformUsers(newSortedList);
    }
    if (filteredCard == "Assets") {
      setAssets(newSortedList2);
    }
    if (filteredCard == "All") {
      setPlatformUsers(newSortedList);
      setAssets(newSortedList2);
    }
  };

  const active = (tabName) => {
    if (tabName === filteredCard) return "active";
    else return "";
  };

  return (
    <div className="platform">
      <div className="pageTitle">
        <p style={{ textTransform: "uppercase" }}>
          EDIT PLATFORM {filteredCard} SETTINGS HERE
        </p>
        <div className="pageHeader">
          <b>Platform</b> {">"} {filteredCard}
        </div>
      </div>
      <div className="pageContent">
        <div className="searchBar">
          <div className="inputBorder">
            <input
              type="text"
              placeholder="Search "
              value={search}
              onChange={handleChange}
            ></input>
            <button
              className="cancel"
              onClick={() => {
                setSearch("");
                setAssets(context.userInfo.assets);
                setPlatformUsers(context.userInfo.users);
              }}
            >
              <img src={xIcon} alt=""></img>
            </button>
          </div>
          <div className="filterBar">
            <div className="top">Filter by</div>
            <div className="bottom">
              <button
                onClick={() => updateFilteredCard("All")}
                className={`${active("All")}`}
              >
                All
              </button>
              <button
                onClick={() => updateFilteredCard("Assets")}
                className={`${active("Assets")}`}
              >
                Assets
              </button>
              <button
                onClick={() => updateFilteredCard("Users")}
                className={`${active("Users")}`}
              >
                Users
              </button>
            </div>
          </div>
        </div>

        <hr style={{ margin: "20px 0px" }} />
        <div className="cardsSection">
          {platformUsers && filteredCard === "Users" ? (
            platformUsers.map((row, i) => (
              <Link
                to={`/UserName/?u=${row.user_id}`}
                className="userCard"
                key={i + "usercard"}
              >
                <div className="userName">
                  {row.first_name + " " + row.last_name}
                </div>
                <div className="email">{row.email}</div>
                <div className="phone">{row.phone}</div>
              </Link>
            ))
          ) : assets && filteredCard === "Assets" ? (
            <>
              {assets.map((row1, i) => (
                <Link
                  to={`/EditAsset?l=${row1.lease_id}`}
                  className="assetCard"
                  key={i + "assetcard"}
                >
                  <div className="year">
                    {row1.year + " " + row1.make + " " + row1.model}
                  </div>
                  <div className="token">
                    Token issued:{" "}
                    <b style={{ marginLeft: "5px" }}>{row1.tokens_issued}</b>
                  </div>
                  <div className="months">
                    Months:{" "}
                    <b style={{ marginLeft: "5px", fontWeight: "600" }}>
                      {Math.round(
                        moment(row1.end_date).diff(moment(), "months", true)
                      )}
                    </b>
                  </div>
                </Link>
              ))}
            </>
          ) : (
            assets &&
            platformUsers && (
              <>
                {platformUsers.map((row, i) => (
                  <Link
                    to={`/UserName/?u=${row.user_id}`}
                    className="userCard"
                    key={i + "usercard2"}
                  >
                    <div className="userName">
                      {row.first_name + " " + row.last_name}
                    </div>
                    <div className="email">{row.email}</div>
                    <div className="phone">{row.phone}</div>
                  </Link>
                ))}
                {assets.map((row1, i) => (
                  <Link
                    to={`/EditAsset?l=${row1.lease_id}`}
                    className="assetCard"
                    key={i + "assetcard2"}
                  >
                    <div className="year">
                      {row1.year + " " + row1.make + " " + row1.model}
                    </div>
                    <div className="token">{row1.tokens_issued}</div>
                    <div className="months">
                      {Math.round(
                        moment(row1.end_date).diff(moment(), "months", true)
                      )}
                    </div>
                  </Link>
                ))}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Platform;
