import React, { useState } from "react";
import "./Controls.scss";

import ToggleButton from "./ToggleButton";

function Interface() {
  return (
    <div className="interfacesPage">
      <div className="pageTitle">
        <p>ADMIN VIEW - CONTROL THE PLATFORM</p>
        <div className="pageHeader">Controls</div>
      </div>
      <div className="pageContent">
        <div className="cardsSection">
          <div className="contentLeft">
            <div className="investCard">
              <div className="cardHeader">
                <div className="title">
                  <h1>Investing</h1>
                </div>
                <div className="status">LIVE</div>
              </div>
              <div className="investContent">
                <div className="tokens">
                  <h2>Buy tokens</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Instant Sell</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Community Sell</h2>
                  <ToggleButton />
                </div>
              </div>
            </div>
            <div className="onboardingCard">
              <div className="cardHeader">
                <div className="title">
                  <h1>Onboarding</h1>
                </div>
                <div className="partialStatus">PARTIALLY LIVE</div>
              </div>
              <div className="investContent">
                <div className="tokens">
                  <h2>Allow new users</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>KYC Verification</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>DocuSign</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Vopay Bank Link</h2>
                  <ToggleButton />
                </div>
              </div>
            </div>
            <div className="econCard">
              <div className="cardHeader">
                <div className="title">
                  <h1>ECON</h1>
                </div>
                <div className="status">LIVE</div>
              </div>
              <div className="investContent">
                <div className="tokens">
                  <h2>Buy tokens</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Deposit tokens</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Withdraw tokens</h2>
                  <ToggleButton />
                </div>
              </div>
            </div>
            <div className="lendingCard">
              <div className="cardHeader">
                <div className="title">
                  <h1>Lending</h1>
                </div>
                <div className="status">LIVE</div>
              </div>
              <div className="investContent">
                <div className="tokens">
                  <h2># open loans:</h2>
                  <div className="value">
                    <h3>13</h3>
                  </div>
                </div>
                <div className="tokens">
                  <h2>$ all loans:</h2>
                  <div className="value">
                    <h3>$2560.00</h3>
                  </div>
                </div>
                <div className="buttons">
                  <button
                    style={{ width: "100%" }}
                    className="pauseLoansButton"
                  >
                    PAUSE ALL NEW LOANS
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="contentRight">
            <div className="depositCard">
              <div className="cardHeader">
                <div className="title">
                  <h1>Deposit Funds</h1>
                </div>
                <div className="status">LIVE</div>
              </div>
              <div className="investContent">
                <div className="tokens">
                  <h2>Crypto</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Interac</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Stripe</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Vopay</h2>
                  <ToggleButton />
                </div>
              </div>
            </div>

            <div className="withdrawCard">
              <div className="cardHeader">
                <div className="title">
                  <h1>Withdraw Funds</h1>
                </div>
                <div className="status">LIVE</div>
              </div>
              <div className="investContent">
                <div className="tokens">
                  <h2>Crypto</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Interac</h2>
                  <ToggleButton />
                </div>
                <div className="tokens">
                  <h2>Stripe</h2>
                  <ToggleButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interface;
