import React, { useState, useEffect } from 'react';
import './EditAsset.scss';
import checker from '../../../assets/checker.png';
import moment from 'moment'
import { useUserInfo, useUserInfoUpdate } from "../../../Context/UserContext";

const API = process.env.REACT_APP_API_URL

function EditAsset() {
    const [carInfo, setCarInfo] = useState()
    const [monthsLeft, setMonthsLeft] = useState()
    const [doneUseffect, setDoneUseffect] = useState(false);
    const [tokenOwners, setTokenOwners] = useState();
    const [tokenHistory, setTokenHistory] = useState();
    const context = useUserInfo()

    useEffect(() => {
        if(!doneUseffect){
            var theone = ''
            var L = context.params.get('l')
            context.userInfo && context.userInfo.assets ? theone = context.userInfo.assets.filter(lease => lease.lease_id == L)[0] : console.log('')
            
            context.accessToken && L && fetch(`${API}/getTokensByLease/${L}`,
            {
                method: 'GET',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                }
            })
            .then(response => response.json())
            .then(tokenOwners => {
                fetch(`${API}/getCarHistory/${L}`,{
                    method: 'GET',
                    mode: 'cors',
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${context.accessToken}`
                    }
                })
                .then(response => response.json())
                .then(history => {
                    setTokenOwners(tokenOwners);
                    setTokenHistory(history);
                })
            });
            
            theone != '' && theone != undefined && setCarInfo(theone);
            theone != '' && theone != undefined && setMonthsLeft(Math.round(moment(theone.end_date).diff(moment(), 'months', true)))
            theone != '' && theone != undefined && setDoneUseffect(true)
        } 
            
    }, [context.userInfo, context.params])

  return(
    <div className="editAssetPage">
        <div className="pageTitle">
            <p>PLATFORM</p>
            <div className="pageHeader">Edit Asset</div>
        </div>
            {carInfo && <div className="pageContent">
            {/* Car Info Card */}
            <div className="carInfo">
                <div className="title">
                    <h1>{carInfo.year} {carInfo.make} {carInfo.model}</h1>
                </div>
                <hr/>
                <div className="image">
                    <img src={carInfo.image} alt=""></img>
                </div>
                <div className="content">
                    <p>License <b>{carInfo.lic ? carInfo.lic: 'N/A'}</b></p>
                    <p>VIN <b>{carInfo.vin}</b></p>
                    <p>Lease Id: <b>{carInfo.lease_id}</b></p>
                    <p /><p />
                    <p>Months Left <b>{monthsLeft}</b></p>
                    <p>Effective date <b>{moment(carInfo.start_date).format("YYYY-MM-DD")}</b></p>
                    <p>Maturity date <b>{moment(carInfo.end_date).format("YYYY-MM-DD")}</b></p>
                    <p /><p />
                    <p>Current buyout <b>$ {Math.round((carInfo.prepayment_tax * ((carInfo.months - monthsLeft)/carInfo.months)) + ( carInfo.principle_remaining + (carInfo.carmodity_fee - ((((carInfo.months - monthsLeft))/carInfo.months) * (carInfo.carmodity_fee)))*1.12))}</b></p>
                    <p>Balance remaining <b>$ {carInfo.balance_remaining}</b></p>
                    <p>Monthly payments <b>$ {carInfo.monthly_payment}</b></p>
                    <p /><p />
                    <p>Monthly returns <b>${(carInfo.monthly_payout/100).toFixed(2)}</b></p>
                    <p>Total tokens <b>{carInfo.total_tokens}</b></p>
                    <p>Contract ROI <b>{carInfo.roi}</b></p>
                    <p>Capitl.Amt <b>${carInfo.capitalized_amt}</b></p>
                </div>                   
            </div>
            {/* Asset Info */}
            <div className="assetInfo">
                <div className="assetTokens">
                    <div className="title">
                        <h1>Token Owners</h1>
                    </div>
                    <div className="tokenList">
                    {tokenOwners && tokenOwners.length != 0 ? ( 
                        tokenOwners.map(token => (
                            <div className="tokenCard">
                                <div className="email">{token.email}</div>
                                <div className="count">Count: <b style={{marginLeft: '5px', fontWeight: '600'}}>{token.count}</b></div>
                                <div className="sale">Up to sale: <b style={{marginLeft: '5px', fontWeight: '600'}}>{token.up_for_sale}</b></div>
                            </div>
                        ))
                    ): (
                        <div>No token owners</div>
                    )}    
                    </div> 
                </div>

                <div className="assetHistory">
                    <div className="title">
                        <h1>Asset History</h1>
                    </div>
                    <div className="historyList">
                    {tokenHistory && tokenHistory.length != 0 ? ( 
                        tokenHistory.map(token => (
                            <div className="historyCard">
                                <div className="subject">{token.verb}  <b style={{marginLeft: '5px', fontWeight: '600'}}> {token.adverb}</b></div>
                                <div className="date">{moment(token.datetime).format("MM/YY")}</div>
                            </div>
                        ))
                    ): (
                        <div>No history</div>
                    )}
                    </div>
                </div>
            </div>
            
        </div>}
        
    </div>
  ) 
}

export default EditAsset;
