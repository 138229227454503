import React, { useEffect, useState } from 'react';
import './Deals.scss';
import {  Link } from 'react-router-dom';
import { useUserInfo } from '../../../Context/UserContext';
const API = process.env.REACT_APP_API_URL;

function Deals() {
    const context = useUserInfo();
    const [L, setL] = useState()
    const [dealsDisplay, setDealsDisplay] = useState();
    const [activeDeals, setActiveDeals] = useState();
    const [inactiveDeals, setInactiveDeals] = useState();
    const [tempActiveDeals, setTempActiveDeals] = useState();
    const [tempInactiveDeals, setTempInactiveDeals] = useState();
    const [allDeals, setAllDeals]= useState();
    const [filterState, setFilterState] = useState('All');
    const [search, setSearch] = useState("")

    useEffect(() => {
        context && setL(context.params.get('l'))
     }, [context.params])

    useEffect(() => {
        let tempDeals 
        let sortedLesseeList
       context && context.userInfo &&context.userInfo.deals? tempDeals = context.userInfo.deals.filter(deals => deals.dealer_code == L):console.log("");
       context && context.userInfo &&context.userInfo.deals && tempDeals && tempDeals[0] ? sortedLesseeList=tempDeals.sort((a, b) =>new Date(a.start_date) > new Date(b.start_date) ? 1 : -1):console.log("")
        let tempDeals1
        context && context.userInfo &&context.userInfo.deals && sortedLesseeList && sortedLesseeList[0] ?
         tempDeals1 = context.userInfo.deals.filter(deal => deal.leased_status == 'Active'):console.log("");
        let tempDeals2
        context && context.userInfo &&context.userInfo.deals && sortedLesseeList && sortedLesseeList[0] ?
         tempDeals2 = context.userInfo.deals.filter(deal => (deal.leased_status == 'Inactive'||deal.leased_status == 'Terminated'||deal.leased_status == 'Finished')):console.log(""); 
        context && context.userInfo &&context.userInfo.deals && sortedLesseeList && sortedLesseeList[0] &&tempDeals1 && setActiveDeals(tempDeals1);
        context && context.userInfo &&context.userInfo.deals && sortedLesseeList && sortedLesseeList[0] && tempDeals2 && setInactiveDeals(tempDeals2);
        context && context.userInfo &&context.userInfo.deals && sortedLesseeList && sortedLesseeList[0] &&tempDeals1 && setTempActiveDeals(tempDeals1);
        context && context.userInfo &&context.userInfo.deals && sortedLesseeList && sortedLesseeList[0] && tempDeals2 && setTempInactiveDeals(tempDeals2);
        context && context.userInfo &&context.userInfo.deals && sortedLesseeList && sortedLesseeList[0] && setAllDeals(sortedLesseeList)
        context && context.userInfo &&context.userInfo.deals && sortedLesseeList && sortedLesseeList[0] && setDealsDisplay(sortedLesseeList)
    }, [context.userInfo,L, filterState])

    const handleChange = (event) => {
        setSearch(event.target.value)
        let newSortedList = allDeals.filter(l => ((l.year+l.make+l.model).toLowerCase().includes((event.target.value).toLowerCase())||(l.vin).toLowerCase().includes((event.target.value).toLowerCase())))
        let newSortedList1 = activeDeals.filter(l => ((l.year+l.make+l.model).toLowerCase().includes((event.target.value).toLowerCase())||(l.vin).toLowerCase().includes((event.target.value).toLowerCase())))
        let newSortedList2 = inactiveDeals.filter(l => ((l.year+l.make+l.model).toLowerCase().includes((event.target.value).toLowerCase())||(l.vin).toLowerCase().includes((event.target.value).toLowerCase())))
        if (filterState == "Active") {
            setTempActiveDeals(newSortedList1)
        }
        if (filterState == "Inactive") {
            setTempInactiveDeals(newSortedList2)
        }
        if (filterState == "All") {
           setDealsDisplay(newSortedList)
        }


        };

    //Filtering Deals based on filter status (All, Active, Inactive)
    function filterFunction( state){
        // console.log(state,"here")
        if(state == 'Active'){
            setDealsDisplay(dealsDisplay.filter(deal => deal.leased_status == 'Active'));
        } else if(state == 'Inactive'){
            setDealsDisplay(dealsDisplay.filter(deal => deal.leased_status == 'Inactive'));
        } else {
            setDealsDisplay(allDeals);
        }
    
    }

    return (
        context && 
        <div className="deals">
            <div className="pageTitle">
                <p>EDIT DEALERSHIP SETTINGS HERE</p>
                <div className="pageHeader">Deals</div>
            </div>
            <div className="pageContent">
                <div className="searchBar">
                     <div className="inputBorder">
                        <input type="text" placeholder="Search "
                         value={search}
                         onChange={handleChange}>
                         </input>
                        <button className="cancel"
                         onClick={() => {setSearch('')
                         setDealsDisplay(allDeals)
                         setTempInactiveDeals(inactiveDeals)
                         setTempActiveDeals(activeDeals)
                         }}>X</button>
                    </div>
                    <div className="filterBar">
                        <div className="top">Filter by</div>
                        <div className="bottom">
                            <button className={filterState == 'All' ? ('activeFilter'): ('')} onClick={() => {
                                setFilterState('All')
                            }}>All</button>
                            <button className={filterState == 'Active' ? ('activeFilter'): ('')} onClick={() => {
                                setFilterState('Active')
                         }}>Active</button>
                            <button className={filterState == 'Inactive' ? ('activeFilter'): ('')} onClick={() => { 
                                setFilterState('Inactive')
                        }}>Inactive</button>
                        </div>
                    </div>
                </div>

                <hr style={{margin: '20px 0px'}}/>
                <div className="dealsSection">
                    {tempActiveDeals && tempActiveDeals[0] && filterState == 'Active' ? (
                        tempActiveDeals.map(deal => {            
                        return(
                        <Link to={{
                            pathname:`/User/?l=${deal.lease_id}`,
                          }}>
                            <div className="dealCard">  
                                <div className="year">
                                    {deal.year} {deal.make} {deal.model}
                                </div>
                                <div className="email">
                                    {deal.vin}
                                </div>
                                <div className="status">
                                    {deal.application_status=="Pending"?"NEW DEAL !":deal.leased_status}
                                </div>
                            </div>
                        </Link >)
                        })
                     )
                     : 
                     tempInactiveDeals && tempInactiveDeals[0] && filterState == 'Inactive' ?(
                        tempInactiveDeals.map(deal => {            
                            return(
                            <Link to={{
                                pathname:`/User/?l=${deal.lease_id}`,
                              }}>
                                <div className="dealCard">  
                                    <div className="year">
                                        {deal.year} {deal.make} {deal.model}
                                    </div>
                                    <div className="email">
                                        {deal.vin}
                                    </div>
                                    <div className="status">
                                        {deal.application_status=="Pending"?"NEW DEAL !":deal.leased_status}
                                    </div>
                                </div>
                            </Link >)
                            })
                     ):
                     dealsDisplay&& dealsDisplay[0] && filterState == 'All'?(
                        dealsDisplay.map(deal => {            
                            return(
                            <Link to={{
                                pathname:`/User/?l=${deal.lease_id}`,
                              }}>
                                <div className="dealCard">  
                                    <div className="year">
                                        {deal.year} {deal.make} {deal.model}
                                    </div>
                                    <div className="email">
                                        {deal.vin}
                                    </div>
                                    <div className="status">
                                        {deal.application_status=="Pending"?"NEW DEAL !":deal.leased_status}
                                    </div>
                                </div>
                            </Link >)
                            })
                     ):
                    <div className="dealCard">No deals yet</div>}
                </div>
            </div>
        </div>
    )
}

export default Deals
