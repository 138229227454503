import React, { useState, useEffect } from "react";
import "./Users.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import checker from "../../assets/checker.png";
import xIcon from "../../assets/xIcon.png";
import { useAuth0 } from "../../react-auth0-spa";
import { useUserInfo, useUserInfoUpdate } from "../../Context/UserContext";
import { dialogContentTextClasses } from "@mui/material";

function Operations() {
  const context = useUserInfo();
  const [Info, setInfo] = useState();
  const [search, setSearch] = useState("");
  const [sortedList, setsortedList] = useState();
  const [leaseOption, SetLeaseOption] = useState(false);
  const [platformUsers, setPlatformUsers] = useState();

  useEffect(() => {
    // context.userInfo.deals &&
    //   console.log(context.userInfo.deals, "context-deals");
    //let lesseeList = context.userInfo.leases
    context.userInfo && context.userInfo.deals && setInfo(context.userInfo.deals);

    let sortedUserList;
    context.userInfo
      ? (sortedUserList = context.userInfo.users.sort((a, b) =>
          a.last_name.localeCompare(b.last_name)
        ))
      : console.log("");
    context.userInfo && context.userInfo.users && setPlatformUsers(sortedUserList);
  }, [context.userInfo]);

  const Infofilter = (event) => {
    setSearch(event.target.value);
    setsortedList(context.userInfo.deals)
    let newSortedList = sortedList.filter(
      (l) =>
        (l.first_name + l.last_name)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (l.year + " " + l.make + "" + l.model)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        l.phone.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.user_id.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.lease_id.toLowerCase().includes(event.target.value.toLowerCase())
    )
    setInfo(newSortedList)
  }

  const Usersfilter = (event) => {
    setSearch(event.target.value);
    setsortedList(context.userInfo.users)
    let newSortedList = sortedList.filter(
      (l) =>
        (l.first_name + l.last_name)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (l.year + " " + l.make + "" + l.model)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        l.phone.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.user_id.toLowerCase().includes(event.target.value.toLowerCase())
       
    )
    setPlatformUsers(newSortedList)
  }


  const handleChange = (event) => {
    setSearch(event.target.value);
    let newSortedList;
    if(leaseOption==false) 
    { setsortedList(context.userInfo.deals)
     newSortedList = sortedList.filter(
      (l) =>
        (l.first_name + l.last_name)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (l.year + " " + l.make + "" + l.model)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        l.phone.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.user_id.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.lease_id.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.email.toLowerCase().includes(event.target.value.toLowerCase())
    )
    setInfo(newSortedList)
     }
     else{
      setsortedList(context.userInfo.users)
     newSortedList = sortedList.filter(
      (l) =>
        (l.first_name + l.last_name)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (l.year + " " + l.make + "" + l.model)
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        l.phone.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.user_id.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.email.toLowerCase().includes(event.target.value.toLowerCase())
       
    )
    setPlatformUsers(newSortedList)
    }
    
  };

  // console.log(context.userInfo.deals,"bjhbewu")
  return context && context.userInfo && Info ? (
    <div className="operationsPage">
      <div className="pageTitle">
        <p>ASSET CONTRACTS - SEARCH BY CUSTOMER</p>
        <div className="pageHeader">Users</div>
      </div>
      <div className="pageContent">
        <div className="searchArea">
          <div className="searchBar">
            <div className="inputBorder">
              <input
                type="text"
                placeholder="Search "
                value={search}
                onChange={handleChange}
              />
              <button
                className="cancel"
                onClick={() => {
                  setSearch("");
                  setInfo(context.userInfo.deals);
                }}
              >
                <img src={xIcon} alt=""></img>
              </button>
            </div>
          </div>
          <div className="toggleButtons">
            <button
              className={`${!leaseOption ? "active" : ""}`}
              onClick={() => {
                SetLeaseOption(false);
              }}
            >
              LEASE
            </button>
            <button
              className={`${leaseOption ? "active" : ""}`}
              onClick={() => {
                SetLeaseOption(true);
              }}
            >
              INVEST
            </button>
          </div>
        </div>
        <hr style={{ margin: "20px 0px", height: "2px" }} />
        {/*rows*/}

        <div className="operationSection" style={{ marginBottom: "40px" }}>
          {context && leaseOption == false
            ? Info.map((row, i) => (
                <Link
                  style={{ textDecoration: "None", color: "black" }}
                  to={{
                    pathname: `/User/?l=${row.lease_id}`,
                  }}
                  key={i}
                >
                  <div className="operationCard">
                    <div className="customerName">
                      {row.first_name + " " + row.last_name}
                    </div>
                    <div className="yearMakeModel">
                      {row.email}
                    </div>
                    <div className="phoneNumber">
                      {row.phone == "NaN" ? " " : row.phone}
                    </div>
                  </div>
                </Link>
              ))
            : platformUsers.map((row, i) => (
                <Link
                  to={`/UserName/?u=${row.user_id}`}
                  className="userCard"
                  key={i + "usercard"}
                >
                  <div className="userName">
                    {row.first_name + " " + row.last_name}
                  </div>
                  <div className="email">{row.email}</div>
                  <div className="phone">{row.phone}</div>
                </Link>
              ))}
        </div>
      </div>
    </div>
  ) : (
    <div>Loading....</div>
  );
}

export default Operations;
