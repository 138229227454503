import React, {useEffect, useState} from 'react';
import './AddDealer.scss';
import { useUserInfo, useUserInfoUpdate } from '../../../Context/UserContext';
import { useHistory } from "react-router-dom";
import polygon from '../../../assets/Polygon.png';

const API = process.env.REACT_APP_API_URL;

function AddDealer() {
    const context = useUserInfo();
    const resetTheClock = useUserInfoUpdate()
    let history = useHistory();
    //State for Add Dealership info
    const [dealerName, setDealerName] = useState('');
    const [dealerPhone, setDealerPhone] = useState('');
    const [dealerAddress, setDealerAddress] = useState('');
    const [dealerCity, setDealerCity] = useState('');
    const [dealerProvince, setDealerProvince] = useState('');
    const [dealerCountry, setDealerCountry] = useState('');
    const [dealerPostalCode, setDealerPostalCode] = useState('');
    const [dealerEmail, setDealerEmail] = useState('');
    const [dealerWebsite, setDealerWebsite] = useState('');

    //State for Add Managers info
    const [managerFirstName, setManagerFirstName] = useState('');
    const [managerLastName, setManagerLastName] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [managerPhone, setManagerPhone] = useState('');

    //State to store managers
    const [dealerManagers, setDealerManagers] = useState([]);

    const [codeNum, setCodeNum] = useState();
    const [errors, setErrors] = useState([]);
    const [manErrors, setManErrors] = useState([]);

    useEffect(() => {
        codeCreation();
    }, [context.userInfo])

    function onSubmit(){
        if(dealerName == '' || dealerAddress == '' || dealerCity == '' || dealerProvince == '' || dealerCountry == '' || dealerPostalCode == '' || (dealerPhone =='' && (dealerEmail == '' || !dealerEmail.includes('@'))) ){
            alert('Please fill the required information ');

            let tempErrors = [];
            if(dealerName=='') tempErrors.push('name');
            if(dealerAddress=='') tempErrors.push('address');
            if(dealerCity=='') tempErrors.push('city');
            if(dealerProvince=='') tempErrors.push('province');
            if(dealerCountry=='') tempErrors.push('country');
            if(dealerPostalCode == '') tempErrors.push('postal');
            if(dealerPhone =='') tempErrors.push('phone');
            if(dealerEmail == '' || !dealerEmail.includes('@')) tempErrors.push('email');
            setErrors(tempErrors);

        } else{
            addDealership();
            dealerManagers.map((manager) => addManagers(manager));
            history.push(`/Dealership/?l=CAD-BC-${codeNum+1}`);
        }
    }

    //Find the next code number for 'CAD-BC-{}'
    function codeCreation(){
        if(context.userInfo){
            let codeList = context.userInfo.dealers.filter(dealer => dealer.dealer_code.includes('CAD-BC'));
            setCodeNum(codeList.length);
        }
    }

    //Send dealership info to server
    function addDealership(){
        fetch(`${API}/createDealership`,
        {
            method: 'POST',
            mode: 'cors',
            headers:
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${context.accessToken}`
            },
            body: JSON.stringify({
                name: dealerName,
                address: dealerAddress,
                city: dealerCity,
                province: dealerProvince,
                country: dealerCountry,
                postal_code: dealerPostalCode,
                phone: dealerPhone,
                email: dealerEmail,
                website: dealerWebsite,
                dealer_code: `CAD-BC-${codeNum+1}`
            })
        })
        .then(res => resetTheClock());
    }

    //Send managers info into the server
    function addManagers(managerInfo){
        fetch(`${API}/createEmployee`,
        {
            method: 'POST',
            mode: 'cors',
            headers:
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${context.accessToken}`
            },
            body: JSON.stringify({
                first_name: managerInfo.first_name,
                last_name: managerInfo.last_name,
                email: managerInfo.email,
                phone: managerInfo.phone,
                manager: true,
                dealer_code: `CAD-BC-${codeNum+1}`
            })
        })
        .then(res => resetTheClock());
    }

    //Add manager into state
    function addManager(evt){
        evt.preventDefault();

        const re = /^[0-9\b]+$/;
        let d = isNaN(managerPhone) ? 1 : managerPhone

        if(!managerEmail.includes('@') || !re.test(d) || managerEmail =='' || managerPhone == '' || managerFirstName=='' || managerLastName==''){
            window.alert('Information are not correct! Please try again');

            let temp = [];
            if(managerFirstName=='') temp.push('firstName');
            if(managerLastName=='') temp.push('lastName');
            if(!managerEmail.includes('@')) temp.push('email');
            if(!re.test(d)) temp.push('phone');
            setManErrors(temp);

        } else{
            const tempManager = {
                first_name: managerFirstName,
                last_name: managerLastName,
                email: managerEmail,
                phone: managerPhone,
                onExpand: false,
            }
            setDealerManagers(dealerManagers => [...dealerManagers, tempManager]);
    
            //Reset the input field for Add Manager
            setManagerFirstName('');
            setManagerLastName('');
            setManagerEmail('');
            setManagerPhone('');
            setManErrors([]);
        }
        
    }

    function expandManager(index){
        let tempManagers = [...dealerManagers];
        tempManagers[index] = {...tempManagers[index], onExpand:!tempManagers[index].onExpand}        
        setDealerManagers(tempManagers);
    }

    function deleteManager(index){
        let tempManagers = [...dealerManagers];
        tempManagers.splice(index,1);
        setDealerManagers(tempManagers);
    }

    function phoneChange(e) {
        const re = /^[0-9\b]+$/;
        let d = isNaN(e.target.value) ? 1 : e.target.value
        if (d === '' || re.test(d)) {
            setDealerPhone(d);
        }
    }
                  
    function postalChange(e){    
        if (e.target.value.length <= 6) {
            setDealerPostalCode(e.target.value);    
        }
    }

    return (
        <div className="addDealer">
            <div className="pageTitle">
                <p>CREATE A NEW DEALERSHIP AND ASSIGN MANAGERS</p>
                <div className="pageHeader">Add Dealer</div>
            </div>
            {/* <div className="pageContent"> */}
            <div className="pageContent">
                <div className="dealershipSection">
                    <div className="addDealership">
                        <div className="title">
                            <h1>Add New Dealership</h1>
                        </div>
                        <div className="inputs">
                            <div>Dealership Name<input className={errors.includes("name") && 'errorInput'} placeholder="Name" onChange={e => setDealerName(e.target.value)}></input></div>
                            <div>Address<input className={errors.includes("address") && 'errorInput'} type="address" placeholder="Address" onChange={e => setDealerAddress(e.target.value)}></input></div>
                            <div>City<input className={errors.includes("city") && 'errorInput'} type="city" placeholder="City" onChange={e => setDealerCity(e.target.value)}></input></div>
                            <div>Province<input className={errors.includes("province") && 'errorInput'} type="province" placeholder="Province" onChange={e => setDealerProvince(e.target.value)}></input></div>
                            <div>Country<input className={errors.includes("country") && 'errorInput'} type="country" placeholder="Country" onChange={e => setDealerCountry(e.target.value)}></input></div>
                            <div>Postal Code<input className={errors.includes("postal") && 'errorInput'} placeholder="Postal Code" value={dealerPostalCode} onChange={(e) => postalChange(e)}></input></div>
                            <div>Dealership Phone (Optional)<input className={errors.includes("phone") && 'errorInput'} placeholder="Phone" value={dealerPhone}  onChange={(e) => phoneChange(e)}></input></div>
                            <div>Dealership Email (Optional)<input className={errors.includes("email") && 'errorInput'} type="email" placeholder="Email" onChange={e => setDealerEmail(e.target.value)}></input></div>
                            <div>Dealership Website<input placeholder="Website" onChange={e => setDealerWebsite(e.target.value)}></input></div>
                        </div>
                    </div>
                </div>
                
                <div className="managerSection">
                    <form className="addManagers" onSubmit={addManager}>
                        <div className="title">
                            <h1>Add Managers</h1>
                        </div>
                        <div className="inputs">
                            <div>First Name<input placeholder="First Name" className={manErrors.includes("firstName") && 'errorInput'} value={managerFirstName} onChange={e => setManagerFirstName(e.target.value)}></input></div>
                            <div>Last Name<input placeholder="Last Name" className={manErrors.includes("lastName") && 'errorInput'} value={managerLastName} onChange={e => setManagerLastName(e.target.value)}></input></div>
                            <div>Email<input placeholder="Email" className={manErrors.includes("email") && 'errorInput'} value={managerEmail} onChange={e => setManagerEmail(e.target.value)}></input></div>
                            <div>Cellphone<input placeholder="Cellphone" className={manErrors.includes("phone") && 'errorInput'} value={managerPhone} onChange={e => setManagerPhone(e.target.value)}></input></div>
                        </div>
                        <div className="button">
                            <button type="submit">SAVE</button>
                        </div>
                    </form>

                    {/* Display list of managers */}
                    <div className="managerList">
                        {dealerManagers.length > 0 && (
                            dealerManagers.map((manager, index) =>
                                <div className="managerCard">
                                    <div className="managerCardTop">
                                        <div className="managerName">
                                            <h1>{manager.first_name} {manager.last_name}</h1>
                                        </div>
                                        <button className="button" onClick={() => expandManager(index)}>
                                            <img src={polygon} alt="" className={manager.onExpand ? ('expandPolygon') : ('')}></img>
                                        </button>
                                    </div>
                                    {manager.onExpand ? ( 
                                        <>
                                        <div className="managerInfo">
                                            <p><b>Email: </b> {manager.email}</p>
                                            <p><b>Phone: </b> {manager.phone}</p>
                                        </div>
                                        <div className="deleteButton">
                                            <button onClick={() => deleteManager(index)}>DELETE</button>
                                        </div>
                                        </>
                                        
                                    ) : ( 
                                        <></>
                                    )}
                                    
                                </div>
                            )
                        )}
                    </div>

                    <div className="submitButton">
                        <button onClick={onSubmit}>SUBMIT INFO</button>
                    </div>
                </div>
                </div>
            {/* </div> */}
        </div>
    )
}

export default AddDealer
