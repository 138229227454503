import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import xIcon from '../../../assets/xIcon.png';
import './UserName.scss';

const API = process.env.REACT_APP_API_URL

function CustomEconDebit(props) {
  const { user_id , open, closePopUp, accessToken, resetTheClock} = props;
  const [amount, setAmount] = useState()

  const onChange = key => (e) => {
    const re = /^[0-9\b]+$/;
    let d = isNaN(e.target.value) ? 1 : e.target.value
    if (d === '' || re.test(d)) {
        setAmount(d);
    }
}

const econDebit = () => {
    fetch(`${API}/econDebit`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ user_id, tier : amount })
    })
        .then(itstime => {window.confirm('Stop giving people free shit Bhatti.'); resetTheClock(); closePopUp()})
}
return (
    <Dialog
        open={open}
        onClose={closePopUp}
        onBackdropClick={closePopUp}>
        <div className="sellTokenContentCard">
          <div className="sellTokenTitle">
              <div className="title">Gift Econ</div>
              <button className="close" onClick={closePopUp}>
                  <img src={xIcon} alt=""></img>
              </button>
          </div>
          <div className="sellTokenInput">
            <input onChange={onChange()} placeholder="quantity" value={amount} className="quantity"/>
            <button onClick={econDebit}><p>Send <b>ECON</b></p></button>
          </div>
        </div>
    </Dialog>
)

}
export default CustomEconDebit