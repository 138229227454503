import React, { useState, useEffect } from "react";
import "./Dealers.scss";
import { Link } from "react-router-dom";
import { useUserInfo } from "../../Context/UserContext";
import { Hidden } from "@mui/material";
import xIcon from "../../assets/xIcon.png";

function Dealers() {
  const context = useUserInfo();
  const [dealers, setDealers] = useState();
  const [search, setSearch] = useState("");

  useEffect(() => {
    context.userInfo &&
      context.userInfo.dealers &&
      setDealers(context.userInfo.dealers);
  }, [context.userInfo]);

  const handleChange = (event) => {
    setSearch(event.target.value);
    let sortedList = context.userInfo.dealers.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    let newSortedList = sortedList.filter(
      (l) =>
        l.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        l.dealer_code
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        l.postal_code.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDealers(newSortedList);
  };

  return context && context.userInfo && dealers ? (
    <div className="dealersPage">
      <div className="pageTitle">
        <p>EDIT DEALERSHIP SETTINGS HERE</p>
        <div className="pageHeader">Dealers</div>
      </div>
      <div className="pageContent">
        <div className="searchBar" style={{ paddingBottom: "15px" }}>
          <div className="inputBorder">
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={handleChange}
            ></input>
            <button
              className="cancel"
              onClick={() => {
                setSearch("");
                setDealers(context.userInfo.dealers);
              }}
            >
              <img src={xIcon} alt=""></img>
            </button>
          </div>
          {/* <div className="filterBar">
                        <div className="top">Filter by</div>
                        <div className="bottom">
                            <button style={{ fontWeight: '700' }}>All</button>
                            <button>Country</button>
                            <button type="select">Province</button>
                        </div>
                    </div>*/}
        </div>

        <hr style={{ margin: "20px 0px" }} />
        <div className="dealersSection">
          <Link to="/AddDealer">
            <div className="addNewDealership">+ Add New Dealership</div>
          </Link>
          {context &&
            dealers &&
            dealers.map((row, i) => (
              <Link
                style={{ textDecoration: "None", color: "black" }}
                to={{ pathname: `/Dealership/?l=${row.dealer_code}` }}
              >
                <div className="dealerCard">
                  <div className="name">{row.name}</div>
                  <div style={{ fontWeight: "600" }} className="phoneNumber1">
                    {row.dealer_code}
                  </div>
                  <div className="phoneNumber2">{row.postal_code}</div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  ) : (
    <div>Loading....</div>
  );
}

export default Dealers;
