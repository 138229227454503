import React, { useState, useEffect } from "react";
import "./UserName.scss";
import SendTextPopUp from "../../SendTextPopUp.js";
import CustomEconDebit from "./CustomEconDebit";
import Dialog from "@mui/material/Dialog";
//import WalletConfirmation from './WalletConfirmation';
import moment from "moment";
import { useUserInfo, useUserInfoUpdate } from "../../../Context/UserContext";
const API = process.env.REACT_APP_API_URL;


function UserAction({
  customer,
  accessToken,
  aum,
  withdrawals,
  resetTheClock,
}) {
  const context = useUserInfo();
  const [walletAmount, setWalletAmount] = useState();
  const [topup_amount, setTopupAmount] = useState();
  const [withdrawal_amount, setWithdrawalAmount] = useState();
  const [method, setmethod] = useState();
  const [password, setPassword] = useState();
  const [password1, setPassword1] = useState();
  const [encore, setEncore] = useState();
  const [editing, setEditing] = useState(false);
  const [reason, setReason] = useState(" ");
  const [open, setOpen] = useState();
  const [open2, setOpen2] = useState();
  const [supports, setSupports] = useState();


  useEffect(() => {
    customer && setEncore(customer.encore.toString());
    context && context.user

    function getAllInfo(id) {
      fetch(`${API}/getFeedback/${id}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((supports) => {
          setSupports(supports);
        });
    }

    customer && customer.user_id && getAllInfo(customer.user_id);
  }, [customer, context.user]);

  const setEncoreOption = (encore) => {
    if (encore != null) {
      setEncore(encore);
      fetch(`${API}/toggleEncore`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ user_id: customer.user_id, encore }),
      }).then((itstime) => resetTheClock());
    }
  };
  const walletChange = (key) => (e) => {
    const re = /^[0-9\b]+$/;
    let d = isNaN(e.target.value) ? 1 : e.target.value;
    if (d === "" || re.test(d)) {
      setWalletAmount(d);
    }
  };

  function econDebit(tier) {
    fetch(`${API}/econDebit`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ user_id: customer.user_id, tier }),
    }).then(
      window.confirm("Stop giving people free shit Bhatti."),
      resetTheClock()
    );
  }

  function denyText() {
    fetch(`${API}/denyWithdrawal`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        count: withdrawals[0].amount,
        method: withdrawals[0].method,
        user_id: customer.user_id,
        reason: reason,
      }),
    }).then(
      fetch("https://hooks.zapier.com/hooks/catch/7559792/3mc5m3q/", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          phone: customer.phone,
          email: customer.email,
          firstName: customer.first_name,
          title: "Withdrawal Declined",
          body: `Your Wallet Withdrawal Request for CAD ${withdrawals[0].amount} has been declined. Reason: ${reason}`,
          final: "If you have any questions, get in contact to learn more. "
        }),
      }).then(
        window.confirm("Withdrawal Denied! Notification sent."),
        window.location.reload()
      )
    )
  }

  function approveWithdrawal() {
    const details = {};
    details["First Name"] = customer.last_name;
    details["Last Name"] = customer.first_name;
    details["Email"] = customer.email;
    details["Amount"] = withdrawals[0].amount;
    const queryString = Object.keys(details).map(key => key + '=' + details[key]).join('&');
    fetch(`${API}/approveWithdrawal`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        count: withdrawals[0].amount,
        method: withdrawals[0].method,
        user_id: customer.user_id,
        reason: "NaN",
        approved_by: context.user.email,
        user_name: customer.first_name + customer.last_name
      }),
    }).then(
      fetch(`https://hooks.zapier.com/hooks/catch/7559792/32djzup/?${queryString}`, {
        method: "POST",
        mode: "cors",
      }).then(
        fetch("https://hooks.zapier.com/hooks/catch/7559792/3mc5m3q/", {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            phone: customer.phone,
            email: customer.email,
            firstName: customer.first_name,
            title: "Withdrawal Fulfilled",
            body: `Your Wallet Withdrawal Request for CAD $${withdrawals[0].amount} has been fulfilled. The amount has been sent by Interac e-Transfer to ${customer.email}. Allow 1 hour for the funds to be deposited.`,
            final: "If you have any questions, get in contact to learn more. "
          }),
        })
      ),
      window.confirm("Withdrawal approved!"),
      setEditing(false),
      window.location.reload()
    );
  }

  function walletDebit() {
    if (walletAmount <= 0 || walletAmount==undefined) {
      console.log(walletAmount," the amount")
      window.alert("Invalid topup amount")
    }
    else{
      console.log(walletAmount," the amount")  
    fetch(`${API}/walletDebit`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user_id: customer.user_id,
        amount: walletAmount * 100,
        payouts_owing: customer.payouts_owing,
      }),
    }).then(
      window.confirm("wallet topup complete"),
      setEditing(false),
      resetTheClock()
    );
    }
  }

  function instantRun() {
    fetch(`${API}/instantRun/${customer.user_id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(window.confirm("instant run successful."), resetTheClock());
  }



  function checkStatus(who, complaint) {
    fetch(`${API}/resolveFeedback`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        who: who,
        complaint: complaint,
      }),
    })
      .then((res) => window.confirm("Resolve feedback successful"))
      .then((itstime) => resetTheClock());
  }




  function numberGoUpBrr() {
    let d = parseInt(walletAmount);
    let e = d + 100;
    setWalletAmount(e);
  }

  function numberGoDownBoo() {
    let d = parseInt(walletAmount);
    let e = d - 100;
    setWalletAmount(e);
  }

  function closePopUp() {
    setOpen(false);
  }
  function closePopUp2() {
    setOpen2(false);
  }

  const [show4, setOpen4] = useState(false);
  const open4 = () => setOpen4(true);
  const handleClose4 = () => {
    setOpen4(false);
  };

  function Capitaltopup() {
    fetch(`${API}/Capitaltopup`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user_id: customer.user_id,
        name: customer.first_name+" "+customer.last_name,
        email: customer.email,
        topup_amount: topup_amount,
        approved_by: context.user.email
      }),
    })
      .then((res) => window.confirm("Resolve feedback successful"))
      .then((itstime) => resetTheClock());
  }

  function CapitalWithdarwal() {
    fetch(`${API}/CapitalWithdarwal`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user_id: customer.user_id,
        name: customer.first_name+" "+customer.last_name,
        email: customer.email,
        method: method,
        withdrawal_amount: withdrawal_amount,
        approved_by: context.user.email,
      }),
    })
      .then((res) => window.confirm("Withdrawal successful!"))
      .then((itstime) => resetTheClock());
  }

  function check1() {
    if (topup_amount <= 0) {
      window.alert("Invalid topup amount")
    }
    else {

      if (password == "hudhaifah@econommi.io") {
        window.alert("withrawal history entry complete")
        Capitaltopup()
      }
      else {
        window.alert("invalid password")
      }
    }
  }

  function wihtdrawalcheck() {
    if (withdrawal_amount <= 0 || method == "") {
      window.alert("Invalid withdrawal amount or method")
    }
    else {
  
      if (password1 == "hudhaifah@econommi.io") {
        window.alert("withrawal history entry complete")
        CapitalWithdarwal()
      }
      else {
        window.alert("invalid password")
      }
    }
  }

  return (
    <div className="userAction">

      {/* Wallet */}
      <div className="walletInfo">
        <div className="title">
          <h1>Wallet</h1>
        </div>

        {/* <div className="walletDisplay">
                        <div className="content">
                            <p>LeaseToken Value: <b>${(customer.token_value)}</b></p>
                        </div>
                </div> */}

        {editing ? (
          <div className="walletContent">
            <div className="enterAmount">
              <div className="amountInfo">
                <input
                  placeholder="Enter top-up amount"
                  value={walletAmount}
                  type="number"
                  onChange={walletChange()}
                ></input>
              </div>
              {/* <div className="buttons">
                <button type="button" onClick={numberGoUpBrr}>
                  +
                </button>
                <button type="button" onClick={numberGoDownBoo}>
                  -
                </button>
              </div> */}
            </div>
            <div className="submitButton" onClick={walletDebit}>
              <button>SAVE</button>
            </div>
          </div>
        ) : (
          <div className="walletDisplay">
            <div className="content">
              <p>
                Amount: <b>${(customer.payouts_owing / 100).toFixed(2)}</b>
              </p>
            </div>
            <div className="button">
              <button onClick={() => setEditing(true)}>Top-Up</button>
            </div>
          </div>
        )}
      </div>
      {/* encore Options */}
      <div className="encoreOptions">
        <div className="title">
          <h1>encore options</h1>
        </div>
        <div className="encoreButtons">
          <button
            className={`${!customer.encore ? "active" : ""}`}
            onClick={() => setEncoreOption(false)}
          >
            OFF
          </button>
          <button
            className={`${customer.encore ? "active" : ""}`}
            onClick={() => setEncoreOption(true)}
          >
            ON
          </button>
        </div>
        <div className="button">
          <button onClick={instantRun}>Instant Run</button>
        </div>
      </div>

      {customer && customer.membership && customer.membership == 'Capital' &&
        <>
          {/* wallet top-up */}
          <div className="withdrawalRequest">
            <div className="title">
              <h1>Wallet Top-up</h1>
            </div>
            <div className="content">
              <div className="inputs">
                <input
                  type="number"
                  placeholder={"amount in $"}
                  onChange={(e) => setTopupAmount(e.target.value)}
                  value={topup_amount}
                ></input>
                <input
                  placeholder={"password"}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                ></input>

              </div>
            </div>
            <div className="buttons">
              <button className="approveButton"
                onClick={() => check1()} >
                Approve
              </button>
            </div>
          </div>

          {/* wallet Withdrawal */}
          <div className="withdrawalRequest">
            <div className="title">
              <h1>Wallet Withdrawal</h1>
            </div>
            <div className="content">
              <div className="inputs">
                <input
                  type="number"
                  placeholder={"amount in $"}
                  onChange={(e) => setWithdrawalAmount(e.target.value)}
                  value={withdrawal_amount}
                ></input>
                <input
                  placeholder={"How are you sending money? ex: interac,bank transfer, ...."}
                  onChange={(e) => setmethod(e.target.value)}
                  value={method}
                ></input>
                <input
                  placeholder={"password"}
                  onChange={(e) => setPassword1(e.target.value)}
                  value={password1}
                ></input>

              </div>
            </div>
            <div className="buttons">
              <button className="approveButton"
                onClick={() => wihtdrawalcheck()} >
                Approve
              </button>
            </div>
          </div>
        </>
      }
      {/* Support Request */}
      <div className="supportRequest">
        <div className="title">
          <h1>Support Requests</h1>
        </div>
        <div className="supportContent">
          {supports && supports[0] ? (
            supports.map((support) => (
              <div className="status">
                <div className="date">
                  {moment(support.date).format("MM/DD") + "     "}
                </div>
                <div className="complaint">{support.complaint}</div>
                <div className="check">
                  <input
                    type="checkbox"
                    checked={support.followed_up}
                    onChange={() =>
                      !support.followed_up &&
                      checkStatus(support.who, support.complaint)
                    }
                  ></input>
                </div>
              </div>
            ))
          ) : (
            <div className="noStatus"> No Support Requests yet</div>
          )}
        </div>
        <div className="button">
          <button onClick={() => setOpen(true)}>Respond with email</button>
        </div>
        <div className="button">
          <button>Mark as complete</button>
        </div>
      </div>
      {/* Withdrawal Request */}
      <div className="withdrawalRequest">
        <div className="title">
          <h1>Withdrawal Requests</h1>
        </div>
        {withdrawals && withdrawals[0] ? (
          <>
            <div className="content">
              <div className="status">
                <p>{withdrawals[0].method}</p>
                <p>${withdrawals[0].amount}</p>
              </div>
            </div>
            <div className="buttons">
              <button className="denyButton"
                onClick={() => {
                  setOpen4(true);
                }}>
                DENY
              </button>
              <button className="approveButton" onClick={approveWithdrawal}>
                APPROVE
              </button>
            </div>
          </>
        ) : (
          <p>No current pending withdrawals</p>
        )}
      </div>
      {/* the dialog bix for the reason for declined starts here */}
      <Dialog open={show4} onBackdropClick={handleClose4}>
        <div className="customSelloutPop">
          <div className="customSelloutTitle">
            <div className="title">Confirm Action</div>
            <button onClick={handleClose4}>X</button>
          </div>
          <p>Please enter the reason for denying the respective withdrawal request</p>
          <input
            placeholder="Please enter the reason for denying the respective withdrawal request"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          ></input>
          <button
            type="button"
            className="cancel"
            onClick={() => {
              handleClose4();
              denyText();
            }}
          >
            Save and Proceed
          </button>
        </div>
      </Dialog>
      {/* ends here */}
      {/* ADD THIS WHEN THERE ARE USER DOCUMENTS IN THE SHAREPOINT
            Files  */}
      <div className="filesCard">
        <div className="title">
          <h1>Files</h1>
        </div>
        <div className="button">
          <button
            onClick={() =>
              window.open(
                `https://econommi.sharepoint.com/sites/operations/Shared%20Documents/operations/FAPP/Users/${customer.email}`
              )
            }
          >
            Open in Sharepoint
          </button>
          <button
            className="copyLink"
            style={{ width: "100%" }}
            onClick={() =>
              navigator.clipboard.writeText(
                `https://econommi.sharepoint.com/sites/operations/Shared%20Documents/operations/FAPP/Users/${customer.email}`
              )
            }
          >
            Copy Link
          </button>
        </div>
      </div>
      <SendTextPopUp
        open={open}
        closePopUp={closePopUp}
        phone={customer.phone}
      />
      <CustomEconDebit
        open={open2}
        closePopUp={closePopUp2}
        user_id={customer.user_id}
        accessToken={accessToken}
        resetTheClock={resetTheClock}
      />
    </div>
  );
}

export default UserAction;
