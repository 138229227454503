import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import xIcon from '../assets/xIcon.png';
import './Platform/UserName/UserName.scss';

const API = process.env.REACT_APP_API_URL

function SendTextPopUp(props) {
  const { open , closePopUp, phone} = props;
  const [message, setMessage] = useState()

  const onChange = key => (e) => {
      // console.log(message)
    setMessage(e.target.value)
}

function text() {
    (message)
    fetch(`https://hooks.zapier.com/hooks/catch/7559792/bf3y6ih/`, {
      method: 'POST',
      mode: 'cors',
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
      body: JSON.stringify({ phone: 7786891596, message })
    })
    .then(window.confirm('Text sent'))
  }
  
return (
    <Dialog
        open={open}
        onClose={closePopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={closePopUp}>
        <div className="sellTokenContentCard">
          <div className="sellTokenTitle">
              <div className="title">Slide into DM's</div>
              <button className="close" onClick={closePopUp}>
                <img src={xIcon} alt=""></img>
              </button>
          </div>
          <div className="sellTokenInput">
            <input onChange={onChange()} placeholder="Text" value={message} className="quantity"/>
              <button onClick={text}>Sent Text</button>
          </div>
        </div>
    
    </Dialog>
)

}
export default SendTextPopUp