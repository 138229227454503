import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import Container from './Container';

const PrivateRoute = ({ component: Component, skipPadding, path, title, ...rest }) => {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  
    useEffect(() => {
      if (loading || isAuthenticated) {
        return;
      }
      const fn = async () => {
        await loginWithRedirect({
          appState: {targetUrl: window.location.pathname}
        });
      };
      fn();
    }, [loading, isAuthenticated, loginWithRedirect, path]);
  
    const render = props => (
      <Container skipPadding={skipPadding} title={title}>
        <Component {...props} />
      </Container>
    );
  
    return <Route path={path} render={render} {...rest} />;
  };
  
  export default PrivateRoute;