import React, { useState, useContext, useEffect } from "react";
import { useAuth0 } from "../react-auth0-spa";
import moment from "moment";
//import env from "react-dotenv";
const API = process.env.REACT_APP_API_URL;

const UserContext = React.createContext();
const UserUpdateContext = React.createContext();

export function useUserInfo() {
  return useContext(UserContext);
}

export function useUserInfoUpdate() {
  ////console.log('useuserinfoupdate has been used')
  return useContext(UserUpdateContext);
}

export function UserContextProvider({ children }) {
  const [userInfo, setUserInfo] = useState();
  const [leases, setLessee] = useState();
  const [accessToken, setAccessToken] = useState("");
  const [clock, setClock] = useState(false);
  //const [threshhold, setThreshholds] = useState()
  const { loading, user, getTokenSilently, logout } = useAuth0();
  const [lease, setLease] = useState();
  const [now, setNow] = useState();
  const params = new URLSearchParams(window.location.search);

  function resetTheClock() {
    setClock((whatever) => !whatever);
    ////console.log('clock has been reset')
  }

  useEffect(
    () => {
      const adminUsers =
        //hard coded list of admins
        [
          "info@carmodity.ca",
          "hudhaifahz@gmail.com",
          "hudhaifah@econommi.io",
          "ahmadbhatti947@gmail.com",
          "ahmad@carmodity.ca",
          "arvin@econommi.io",
          "simi7112001@gmail.com",
          "simran@econommi.io",
          "owyn@econommi.io",
          "izzul@econommi.io",
          "izzulsyahmi210@gmail.com"
        ];

      if (loading || !user) {
        return;
      }
      if (!adminUsers.includes(user.email)) {
        //non admin tries to access admin area
        alert("NON ADMIN CREDENTIALS: NO ACCESS");
        window.location.replace("https://carmodity.ca");
        logout();
      }
      getTokenSilently({}).then((response) => setAccessToken(response));

      function getUserInfo() {
        //console.log(user)
        fetch(`${API}/internalSiteLogin`, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((users) => {
            setUserInfo(users);
          });
      }

      accessToken && getUserInfo();

      setInterval(() => {
        setNow(moment().format(" MMMM Do YYYY |  h:mm:ss A"));
      }, 1000);
    }, // maybe doesnt need to run on gettoken silently, or access token
    //}

    [loading, accessToken, clock]
  );

  return (
    <UserContext.Provider
      value={{ accessToken, userInfo, now, user, logout, params }}
    >
      <UserUpdateContext.Provider value={resetTheClock}>
        {children}
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
}
