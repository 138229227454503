import React, { useState, useEffect } from 'react';
import './Dealership.scss';
import { Link } from 'react-router-dom';
import { useUserInfo, useUserInfoUpdate } from "../../../Context/UserContext";
import polygon from '../../../assets/Polygon.png';
import { useHistory } from "react-router-dom";
import plus from '../../../assets/Plus.png';
import xIcon from '../../../assets/xIcon.png';
import moment from 'moment/moment';

const API = process.env.REACT_APP_API_URL;

function Dealership() {
    const context = useUserInfo();
    let history = useHistory();
    const resetTheClock = useUserInfoUpdate();
    const [L, setL] = useState();
    const [workers, setWorkers] = useState();
    const [dealershipInfo, setDealershipInfo] = useState();
    const [deals, setDeals] = useState();

    //State for Dealership Edit Info
    const [editing, setEditing] = useState(false)
    const [editName, setEditName] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [editAddress, setEditAddress] = useState('');
    const [editProvince, setEditProvince] = useState('');
    const [editCity, setEditCity] = useState('');
    const [editCountry, setEditCountry] = useState('');
    const [editPostalCode, setEditPostalCode] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editWebsite, setEditWebsite] = useState('');

    //State for Add Manager
    const [addManager, setAddManager] = useState(false);

    //State for Add Managers info
    const [managerFirstName, setManagerFirstName] = useState('');
    const [managerLastName, setManagerLastName] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [managerPhone, setManagerPhone] = useState('');

    const [manErrors, setManErrors] = useState([]);

    useEffect(() => {
        setL(context.params.get('l'))
    }, [context.params])
 
    useEffect(() => {
        if (context.userInfo) {
            var dealership = context.userInfo.dealers.filter(dealer => dealer.dealer_code == L)
            if (dealership[0]) {
                context.userInfo && context.userInfo.dealers && setDealershipInfo(dealership[0]);
            } else {
                resetTheClock();
            }

            let tempDeals = context.userInfo.deals.filter(deals => deals.dealer_code == L);
            setDeals(tempDeals);
            L && fetchEmployees();
        
        } 

    }, [context.userInfo, L])

    const startEditing = () => {
        setEditing(true)
    }


    function fetchEmployees() {
        fetch(`${API}/getDealerEmployees/${L}`,
            {
                method: 'GET',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                }
            })
            .then(response => response.json())
            .then(employees => {
                setWorkers(employees)
            })
    }

    function shipTrackers() {
        fetch(`https://hooks.zapier.com/hooks/catch/7559792/b085awz/`, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            ship_date:(moment().format('YYYY-MM-DD')),
            dealer_name: dealershipInfo.name,
            dealer_address: dealershipInfo.address,
            dealer_city: dealershipInfo.city,
            dealer_province: dealershipInfo.province,
            dealer_country: dealershipInfo.country,
            dealer_postal: dealershipInfo.postal_code,
            dealer_phone: dealershipInfo.phone,
            package_weight: '6',
            package_height: '0.5',
            package_length: '9.5',
            package_width: '5.75',
          }),
        });
      }

      function shipPaperwork() {
        fetch(`https://hooks.zapier.com/hooks/catch/7559792/b085awz/`, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            ship_date:(moment().format('YYYY-MM-DD')),
            dealer_name: dealershipInfo.name,
            dealer_address: dealershipInfo.address,
            dealer_city: dealershipInfo.city,
            dealer_province: dealershipInfo.province,
            dealer_country: dealershipInfo.country,
            dealer_postal: dealershipInfo.postal_code,
            dealer_phone: dealershipInfo.phone,
            package_weight: '6',
            package_height: '0.5',
            package_length: '10',
            package_width: '13',
          }),
        });
      }

      function shipOnboarding() {
        fetch(`https://hooks.zapier.com/hooks/catch/7559792/b085awz/`, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            ship_date:(moment().format('YYYY-MM-DD')),
            dealer_name: dealershipInfo.name,
            dealer_address: dealershipInfo.address,
            dealer_city: dealershipInfo.city,
            dealer_province: dealershipInfo.province,
            dealer_country: dealershipInfo.country,
            dealer_postal: dealershipInfo.postal_code,
            dealer_phone: dealershipInfo.phone,
            package_weight: '32',
            package_height: '2.5',
            package_length: '9',
            package_width: '11.3',
          }),
        });
      }

    function handleEditDealershipInfo(evt) {
        evt.preventDefault();
        setEditing(false)

        fetch(`${API}/updateDealership`,
            {
                method: 'PUT',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                },
                body: JSON.stringify({
                    name: editName == '' ? (dealershipInfo.name) : editName,
                    address: editAddress == '' ? (dealershipInfo.address) : editAddress,
                    city: editCity == '' ? (dealershipInfo.city) : editCity,
                    province: editProvince == '' ? (dealershipInfo.province) : editProvince,
                    country: editCountry == '' ? (dealershipInfo.country) : editCountry,
                    postal_code: editPostalCode == '' ? (dealershipInfo.postal_code) : editPostalCode,
                    phone: editPhone == '' ? (dealershipInfo.phone) : editPhone,
                    email: editEmail == '' ? (dealershipInfo.email) : editEmail,
                    website: editWebsite == '' ? (dealershipInfo.website) : editWebsite,
                    dealer_code: dealershipInfo.dealer_code
                })
            })
            .then(res => window.confirm('Update successful'))
            .then(itstime => resetTheClock())
            .then(res=>history.push(`/Dealership/?l=${dealershipInfo.dealer_code}`))
    }

    //Add manager into state
    function addManagerFunc(){
        //evt.preventDefault();

        const re = /^[0-9\b]+$/;
        let d = isNaN(managerPhone) ? 1 : managerPhone

        if(!managerEmail.includes('@') || !re.test(d) || managerEmail =='' || managerPhone == '' || managerFirstName=='' || managerLastName==''){
            window.alert('Information are not correct! Please try again');

            let temp = [];
            if(managerFirstName=='') temp.push('firstName');
            if(managerLastName=='') temp.push('lastName');
            if(!managerEmail.includes('@')) temp.push('email');
            if(!re.test(d)) temp.push('phone');
            setManErrors(temp);

        } else{
            fetch(`${API}/createEmployee`,
            {
                method: 'POST',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                },
                body: JSON.stringify({
                    first_name: managerFirstName,
                    last_name: managerLastName,
                    email: managerEmail,
                    phone: managerPhone,
                    manager: true,
                    dealer_code: dealershipInfo.dealer_code
                })
            })
            .then(itstime => resetTheClock())
    
            //Reset the input field for Add Manager
            setManagerFirstName('');
            setManagerLastName('');
            setManagerEmail('');
            setManagerPhone('');
            setManErrors([]);
        }  
    }

    function phoneChange(e) {
        const re = /^[0-9\b]+$/;
        let d = isNaN(e.target.value) ? 1 : e.target.value
        if (d === '' || re.test(d)) {
            setEditPhone(d);
        }
    }
        
    function postalChange(e){    
        if (e.target.value.length <= 6) {
            setEditPostalCode(e.target.value);    
        }
    }

    
    //console.log(dealershipInfo,"infoooo")
    return (
        context &&
        <div className="dealership">
            <div className="pageTitle">
                <p>ACTIONS THAT NEED YOUR ATTENTION</p>
              {  dealershipInfo &&  <div className="pageHeader">{dealershipInfo.name}</div>}
            </div>
            <div className="pageContent">
                {/*Left section starts here it includes tabs:(Dealership info, Files and Inventory)*/}
                <div className="leftSection">
                    <div className="deals">
                        <div className="title">
                            <h1>Deals</h1>
                        </div>
                        <div className="dealContent">
                            {deals && deals[0] ? (
                                deals.map(deal =>
                                    <div className="dealCard">
                                        <div className="yearMakeModel">{deal.year} {deal.make} {deal.model}</div>
                                        <div className="status">{deal.leased_status}</div>
                                    </div>)
                            ) : (
                                <div className="dealCard">No deals Yet</div>
                            )}
                        </div>
                        <div className="button">
                            {deals && deals[0] ? <Link to={`/Deals/?l=${L}`}>
                                <button><img src={polygon} alt=""></img></button>
                            </Link> : ""}
                        </div>
                    </div>
                    <div className="employees">
                        <div className="title">
                            <h1>Employees</h1>
                        </div>
                        <div className="employeeContent">
                            {workers && workers[0] ? (
                                workers.map(employee => (
                                    <div className="dealCard">
                                        <div className="employeeName">{employee.first_name} {employee.last_name}</div>
                                        <div className="salesPerson">{employee.manager ? 'Manager' : 'Salesperson'}</div>
                                    </div>
                                ))
                            ) : (<div className="dealCard">No Employees Listed </div>)}
                        </div>
                        <div className="addManagerButton" onClick={() => setAddManager(true)}>
                            <button><img src={plus} alt=""></img></button>
                        </div>
                    </div>
                    {addManager ? (
                        <div className="addManagerCard">
                             <div className="addManagers" //onSubmit={addManagerFunc}
                             >
                                <div className="title">
                                    <h1>Add Managers</h1>
                                    <button className="closeButton" onClick={() => setAddManager(false)}>
                                        <img src={xIcon} alt=""></img>
                                    </button>
                                </div>
                                <div className="inputs">
                                    <div>First Name<input placeholder="First Name" className={manErrors.includes("firstName") && 'errorInput'} value={managerFirstName} onChange={e => setManagerFirstName(e.target.value)}></input></div>
                                    <div>Last Name<input placeholder="Last Name" className={manErrors.includes("lastName") && 'errorInput'} value={managerLastName} onChange={e => setManagerLastName(e.target.value)}></input></div>
                                    <div>Email<input placeholder="Email" className={manErrors.includes("email") && 'errorInput'} value={managerEmail} onChange={e => setManagerEmail(e.target.value)}></input></div>
                                    <div>Cellphone<input placeholder="Cellphone" className={manErrors.includes("phone") && 'errorInput'} value={managerPhone} onChange={e => setManagerPhone(e.target.value)}></input></div>
                                </div>
                                <div className="button">
                                    <button type="submit" onClick={addManagerFunc}>UPDATE</button>
                                </div>
                            </div>
                        </div>
                     ) : (
                        null
                    )}
                    
                </div>
                {/*Left section ends here */}

                {/*Right section starts here it includes tabs:(Deals, Employees)*/}
                <div className="rightSection">
                    {/* <div className="reviews">
                        <div className="title">
                            <h1>Reviews</h1>
                        </div>
                        <div className="coloredRectangle">
                        </div>
                    </div> */}
                    <div className="dealershipInfo">
                        <div className="title">
                            <h1>Dealership info</h1>
                        </div>
                        
                            <div className="inputs">
                                {dealershipInfo && <>
                                    <input placeholder={dealershipInfo.name == 'NaN' ? 'Dealership Name' : dealershipInfo.name}
                                        disabled={!editing}
                                        onChange={e => setEditName(e.target.value)}
                                        >
                                    </input>

                                    <input placeholder={dealershipInfo.phone == 'NaN' ? 'Phone' : dealershipInfo.phone}
                                        disabled={!editing}
                                        value={editPhone}
                                        onChange={e => phoneChange(e)}
                                       >
                                    </input>

                                    <input placeholder={dealershipInfo.address == 'NaN' ? 'Address' : dealershipInfo.address}
                                        disabled={!editing}
                                        onChange={e => setEditAddress(e.target.value)}
                                      >
                                    </input>

                                    <input placeholder={dealershipInfo.city == 'NaN' ? 'City' : dealershipInfo.city}
                                        disabled={!editing}
                                        onChange={e =>  setEditCity(e.target.value)}
                                       >
                                    </input>

                                    <input placeholder={dealershipInfo.province == 'NaN' ? 'Province' : dealershipInfo.province}
                                        disabled={!editing}
                                        onChange={e =>  setEditProvince(e.target.value)}
                                       >
                                    </input>

                                    <input placeholder={dealershipInfo.country == 'NaN' ? 'Country' : dealershipInfo.country}
                                        disabled={!editing}
                                        onChange={e =>  setEditCountry(e.target.value)}
                                       >
                                    </input>

                                    <input placeholder={dealershipInfo.postal_code == 'NaN' ? 'X#X #X#' : dealershipInfo.postal_code}
                                        disabled={!editing}
                                        value={editPostalCode}
                                        onChange={e => postalChange(e)}
                                        >
                                    </input>

                                    <input disabled placeholder={dealershipInfo.dealer_code == 'NaN' ? 'XXX-XX-##' : dealershipInfo.dealer_code}>
                                    </input>

                                    <input placeholder={dealershipInfo.website == 'NaN' ? 'Dealership Website' : dealershipInfo.website}
                                        disabled={!editing}
                                        onChange={e => setEditWebsite(e.target.value)}
                                        >
                                    </input>

                                    <input placeholder={dealershipInfo.email == 'NaN' ? 'Email' : dealershipInfo.email}
                                        disabled={!editing}
                                        onChange={e => setEditEmail(e.target.value)}
                                        >
                                    </input>
                                </>}
                            </div>
                            {editing ?
                                <div className="button" >
                                    <button className="saveButton" onClick={handleEditDealershipInfo} >SAVE</button>
                                </div> :
                                <div className="button"  >
                                    <button className="editButton" onClick={() => startEditing()}>EDIT</button>
                                </div>}
                       
                    </div>

                   <div className="infoTile">
                        <div className="shipping">
                        <div className="title">
                                    <h1>Shipping</h1>
                                </div>
                            <div className='shippingContent'>
                                <div className="button">
                                <button
                                    onClick={() =>
                                    shipTrackers(
                                    )
                                    }
                                >
                                    Trackers
                                </button>
                                </div>
                                <div className="button">
                                <button
                                    onClick={() =>
                                    shipPaperwork(
                                    )
                                    }
                                >
                                    Package
                                </button>
                                </div>
                                <div className="button">
                                <button
                                    onClick={() =>
                                    shipOnboarding(
                                    )
                                    }
                                >
                                   Onboarding box</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                Right section ends here

            </div>
        </div>
    );
}

export default Dealership;
