import React, { useState, useEffect } from 'react';
import './UserName.scss';
import UserInfo from './UserInfo';
import UserAction from './UserAction';
import SellTokens from './SellTokens';
import moment from 'moment'
import { useUserInfo, useUserInfoUpdate } from "../../../Context/UserContext";
const API = process.env.REACT_APP_API_URL
function UserName() {
    const [activeSection, setActiveSection] = useState('actions')
    const [customer, setCustomer] = useState()
    const [history, setHistory] = useState()
    const [tokens, setTokens] = useState()
    const [withdrawals, setWithdrawals] = useState()
    const context = useUserInfo()
    const resetTheClock = useUserInfoUpdate()
    const [done, setDone] = useState(false);
    const [note, setNote] = useState();

    useEffect(() => {
        var theone = ''
        let u = context.params.get('u')
        context.userInfo && context.userInfo.users ? theone = context.userInfo.users.filter(user => user.user_id == u)[0] : console.log('')
        theone != '' && setCustomer(theone);
        context.accessToken && u && !done && fetch(`${API}/history/${u}`,
            {
                method: 'GET',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                }
            })
            .then(response => response.json())
            .then(history => {
                fetch(`${API}/getTokensByOwner/${u}`,
                    {
                        method: 'GET',
                        mode: 'cors',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${context.accessToken}`
                        }
                    })
                    .then(response => response.json())
                    .then(tokens => {
                        fetch(`${API}/getNotes/${u}`, {
                            method: "GET",
                            mode: "cors",
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: `Bearer ${context.accessToken}`,
                            },
                        })
                            .then((response) => response.json())
                            .then((note) => {
                                setNote(note)
                                fetch(`${API}/getMyWithdrawals/${u}`,
                                    {
                                        method: 'GET',
                                        mode: 'cors',
                                        headers:
                                        {
                                            'Content-Type': 'application/json',
                                            'Accept': 'application/json',
                                            'Authorization': `Bearer ${context.accessToken}`
                                        }
                                    })
                                    .then(response => response.json())
                                    .then(withdrawals => {
                                        let pending = withdrawals.filter(withdrawal => withdrawal.sent === "pending")
                                        setWithdrawals(pending)
                                        setTokens(tokens)
                                        setHistory(history)
                                        setDone(true);

                                    })
                            })
                    })
                    .then(res => res.json);
            })
    }, [context.params])

    const updateActiveSection = (sectionName) => {
        setActiveSection(sectionName);
    }

    const active = (tabName) => {
        if (tabName === activeSection) return 'active'
        else return ''
    }

    return (
        context &&
        <div className="userNamePage">
            <div className="pageTitle">
                <p>EDIT USER INFO FOR PLATFORM</p>
                {customer != undefined && <div className="pageHeader">{customer.first_name + ' ' + customer.last_name} ({customer.membership})</div>}
            </div>
            <div className="mobileButton">
                <div className="buttonContent">
                    <button className={`${active('actions')}`} onClick={() => updateActiveSection('actions')}>Actions</button>
                    <button className={`${active('info')}`} onClick={() => updateActiveSection('info')}>Info</button>
                </div>
            </div>
            {customer && <div className="pageContent">
                <UserAction
                    customer={customer}
                    accessToken={context.accessToken}
                    aum={context.userInfo.aum}
                    withdrawals={withdrawals}
                    resetTheClock={resetTheClock} />
                <UserInfo
                    accessToken={context.accessToken}
                    customer={customer}
                    history={history}
                    assets={tokens}
                    resetTheClock={resetTheClock}
                    note={note} />
            </div>}
            {customer && <div className="pageContentMobile">
                {activeSection === 'actions' ? (
                    <UserAction customer={customer}
                        accessToken={context.accessToken}
                        aum={context.userInfo.aum}
                        withdrawals={withdrawals}
                        resetTheClock={resetTheClock} />
                ) : (
                    <UserInfo
                        accessToken={context.accessToken}
                        customer={customer}
                        history={history}
                        assets={tokens}
                        resetTheClock={resetTheClock}
                        note={note} />
                )}
            </div>}
        </div>
    )
}

export default UserName
