import React, { useState, useEffect } from 'react';
import './Notifications.scss';
import moment from 'moment'
import { Link } from 'react-router-dom';
import xIcon from '../../assets/xIcon.png';
import { useUserInfo, useUserInfoUpdate } from "../../Context/UserContext";

const API = process.env.REACT_APP_API_URL;

function Notifications() {
    const context = useUserInfo();
    const resetTheClock = useUserInfoUpdate();
    const [notifications, setNotifications] = useState()
    const [allUsers, setAllUsers] = useState()
    const [selectedValue, setSelectedValue] = useState(0);

    useEffect(() => {
        context.userInfo && context.userInfo.notifications && setNotifications((context.userInfo.notifications.sort((a, b) => a.completed - b.completed)));
        context.userInfo && context.userInfo.users && setAllUsers(context.userInfo.users);
        context.userInfo && context.userInfo.notifications && sortNotifications();
    }, [context.userInfo])


    const setEmail = (user_id) => {
        var theone = allUsers.filter(oneuser => oneuser.user_id == user_id)
        let email
        theone[0] ? email = theone[0].email : email = " "
        return email
    }

    const allFilter = () => {
        let list = context.userInfo.notifications
        setNotifications(list)
        setSelectedValue(0)
    }

    const operationsFilter = () => {
        let list = context.userInfo.notifications
        let filteredList = list.filter(oneuser => oneuser.type == "Operations")
        setNotifications(filteredList)
        setSelectedValue(1)
    }

    const dealersFilter = () => {
        let list = context.userInfo.notifications
        let filteredList = list.filter(oneuser => oneuser.type == "Dealer")
        setNotifications(filteredList)
        setSelectedValue(2)
    }

    const platformFilter = () => {
        let list = context.userInfo.notifications
        let filteredList = list.filter(oneuser => oneuser.type == "Platform")
        setNotifications(filteredList)
        setSelectedValue(3)
    }

    const sortNotifications = () => {
        let notificationList = context.userInfo.notifications
        let sortedNotificationList = notificationList.sort((a, b) => {
            return moment(b.date) - moment(a.date)
        });
        setNotifications(sortedNotificationList)
    }

    function checkNotification(who, title) {
        fetch(`${API}/completeNotification`,
            {
                method: 'POST',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                },
                body: JSON.stringify({
                    who: who,
                    title: title
                })
            })
            .then(res => window.confirm('Update notification successful'))
            .then(itstime => resetTheClock());
    }


    return (
        <div className="notificationsPage">
            <div className="pageTitle">
                <p>ACTION THAT NEED YOUR ATTENTION</p>
                <div className="pageHeader">Notifications</div>
            </div>
            <div className="pageContent">
                <div className="searchBar">
                    <div className="inputBorder">
                        <input type="text" placeholder="Search "></input>
                        <button className="cancel">
                            <img src={xIcon} alt=""></img>
                        </button>
                    </div>
                    <div className="filterBar">
                        <div className="top">
                            Filter by
                        </div>
                        <div className="bottom">
                            <button
                                style={{ fontWeight: 0 == selectedValue ? 700 : 400 }}
                                onClick={() => allFilter()}>
                                All
                            </button>

                            <button
                                style={{ fontWeight: 1 == selectedValue ? 700 : 400 }}
                                onClick={() => operationsFilter()}>
                                Oper.
                            </button>

                            <button
                                style={{ fontWeight: 2 == selectedValue ? 700 : 400 }}
                                onClick={() => dealersFilter()}>
                                Dealers
                            </button>

                            <button
                                style={{ fontWeight: 3 == selectedValue ? 700 : 400 }}
                                onClick={() => platformFilter()}>
                                Platform
                            </button>

                        </div>
                    </div>
                </div>
                <hr style={{ margin: '20px 0px' }} />
                <div className="notificationSection">
                    {context && notifications && notifications.map((row, i) =>

                        row.title.includes("Withdrawal") ? 
                        <Link style={{ textDecoration: 'None', color: 'black' }}
                            to={{
                                pathname: `/UserName/?u=${row.who}`,
                            }}>
                            <div className="notificationCard">
                                <div className="email">
                                    {allUsers && setEmail(row.who)}
                                </div>
                                <div className="state">
                                    {row.title.includes("Pay lessee") ? "Missed Payment" : `${row.title}`}
                                </div>
                                <div className="date">
                                    {moment(row.date).format("DD/MM/YYYY")}

                                </div>
                                <div className="check">
                                    <input type="checkbox"
                                        checked={row.completed}
                                        onClick={e => e.stopPropagation()}
                                        onChange={() => !row.completed && checkNotification(row.who, row.title)}
                                    ></input>
                                </div>
                            </div>
                        </Link> :
                            <Link style={{ textDecoration: 'None', color: 'black' }}
                                to={{
                                    pathname: `/User/?l=${row.who}`,
                                }}>
                                <div className="notificationCard">
                                    <div className="email">
                                        {allUsers && setEmail(row.who)}
                                    </div>
                                    <div className="state">
                                        {row.title.includes("Pay lessee") ? "Missed Payment" : `${row.title}`}
                                    </div>
                                    <div className="date">
                                        {moment(row.date).format("DD/MM/YYYY")}

                                    </div>
                                    <div className="check">
                                        <input type="checkbox"
                                            checked={row.completed}
                                            onClick={e => e.stopPropagation()}
                                            onChange={() => !row.completed && checkNotification(row.who, row.title)}
                                        ></input>
                                    </div>
                                </div>
                            </Link>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Notifications
