import React, { useState } from "react";
import checker from "../../../assets/checker.png";
import moment from "moment";
import Popover from "@mui/material/Popover";
import ConfirmBox from "./ConfirmBox";
import Dialog from "@mui/material/Dialog";
import "./User.scss";
import vectorIcon from "../../../assets/vector10.png";
import { useUserInfoUpdate } from "../../../Context/UserContext";


window.Buffer = window.Buffer || require("buffer").Buffer;
const API = process.env.REACT_APP_API_URL;
const S3_BUCKET = process.env.S3_BUCKET;
const REGION = process.env.REGION;
const ACCESS_KEY = process.env.ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.YOUR_SECRET_ACCESS_KEY;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
}

const CustomerAction = (props) => {
  const {
    buyout,
    monthsCompleted,
    contract,
    pageInfo,
    fxRate,
    aum,
    missedPaymentNotice,
    repoNotice,
    accessToken,
    num,
    L,
  } = props;
  const resetTheClock = useUserInfoUpdate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open6 = Boolean(anchorEl);
  const id = open6 ? "simple-popover" : undefined;
  const [missedmonth, setMissedmonth] = useState("Missed payment month");
  const [disabled, setDisabled] = useState(true);
  const [payout, setPayout] = useState();
  const [notes, setNotes] = useState();
  const [reason, setReason] = useState(" ");
  const [selloutReason, setSelloutReason] = useState("Please select the reason for Token Sellout.");
  const [deal_end_reason, setDealEndReason] = useState("Please select the reason for Deal End.");
  const [expenses, setExpenses] = useState();
  const [opne1, setOpen1] = useState(false);
  const [opne2, setOpen2] = useState(true);
  const [number, setNumber] = useState();

  const monthList = [
    { status: "Jan" },
    { status: "Feb" },
    { status: "Mar" },
    { status: "Apr" },
    { status: "May" },
    { status: "June" },
    { status: "July" },
    { status: "Aug" },
    { status: "Sept" },
    { status: "Oct" },
    { status: "Nov" },
    { status: "Dec" },
  ];

  // the code for the token selllout popover starts here
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open9 = Boolean(anchorEl1);
  const id1 = open9 ? "simple-popover" : undefined;
  const reasonList1 = [
    { status: "insurance" },
    { status: "buyout" },
    { status: "default" },
    { status: "completed" },
  ];

  function getMinPrepayment(credit, price, leaseType, warranty, docFee, tradeIn, wholesale) {
    let defaultPrepaymentThreshold = 
        credit <= 525 ? 0.4 : 
        credit <= 625 ? 0.2 : 
        credit <= 725 ? 0.1 : 
        credit <= 825 ? 0.05 : 0
    let prepaymentThreshold = 
        credit <= 625 ? 795 : 
        credit <= 750 ? 3000 : price
      let defaultMinPrePayment = leaseType === 'inflexible' ? (0.5 * price)+warranty+docFee-tradeIn :
        Math.max(((defaultPrepaymentThreshold * price))+warranty+docFee-tradeIn,0)
      let apiMinPrePayment = leaseType === 'inflexible' ? Math.max((((price - wholesale)))+warranty+docFee-tradeIn,0) :
        Math.max(-prepaymentThreshold+price-wholesale+warranty+docFee-tradeIn,0)
      let minPrePayment = wholesale === 0 ? defaultMinPrePayment : Math.min(apiMinPrePayment,defaultMinPrePayment)
    return minPrePayment
  }

  function selectStatus1(stat) {
    setSelloutReason(stat);
    setAnchorEl1(null);
  }

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose9 = () => {
    setAnchorEl1(null);
  };

  // the code for the token selllout popover ends here

  // the code for the token dealend popover starts here
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open11 = Boolean(anchorEl2);
  const id2 = open11 ? "simple-popover" : undefined;
  const reasonList2 = [
    { status: "Buyout" },
    { status: "Return" },
    { status: "Unwind" },
    { status: "Total Loss" },
    { status: "Default" },
    { status: "Settlement" },
  ];

  function selectStatus2(stat) {
    setDealEndReason(stat);
    setAnchorEl2(null);
  }

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose11 = () => {
    setAnchorEl2(null);
  };

  // the code for the token dealend popover ends here

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(true);
  };

  const sendMessage1 = () => {
    missedPaymentNotice();
  };
  const sendMessage2 = () => {
    repoNotice();
  };

  function selectStatus(stat) {
    setMissedmonth(stat);
    setAnchorEl(null);
  }

  const [show, setOpen] = useState(false);
  const open = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [show4, setOpen4] = useState(false);
  const open4 = () => setOpen4(true);
  const handleClose4 = () => {
    setOpen4(false);
  };

  const [show5, setOpen5] = useState(false);
  const open5 = () => setOpen5(true);
  const handleClose5 = () => {
    setOpen5(false);
  };

  const [show7, setOpen7] = useState(false);
  const open7 = () => setOpen7(true);
  const handleClose7 = () => {
    setOpen7(false);
  };

  const [show8, setOpen8] = useState(false);
  const open8 = () => setOpen8(true);
  const handleClose8 = () => {
    setOpen8(false);
  };

  const [show10, setOpen10] = useState(false);
  const open10 = () => setOpen10(true);
  const handleClose10 = () => {
    setOpen10(false);
  };

  const handleClose6 = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  //   const Sell1 = () => {
  //     setOpen(true)
  // }

  const tokenSellout = async () => {
    var car = pageInfo.year + " " + pageInfo.make + " " + pageInfo.model;
    fetch(`${API}/tokenBuyOut`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        car_id: pageInfo.car_id,
        lease_id: pageInfo.lease_id,
        monthsCompleted,
        car,
        selloutReason,
      }),
    })
      .then((res) => {
        if (res.status == 200) {
          window.alert("Token Sellout successful");
        } else {
          window.alert("Token Sellout not successful");
        }
      })
      .then((itstime) => resetTheClock());
  };

  const customSellOut = () => {
    var car = pageInfo.year + " " + pageInfo.make + " " + pageInfo.model;
    if (
      window.confirm(
        "Do you really want to initiate Token Sellout for the respective car ?"
      )
    ) {
      if (window.confirm("Are you 100% sure?")) {
        if (payout && expenses) {
          fetch(`${API}/customBuyOut`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              car_id: pageInfo.car_id,
              lease_id: pageInfo.lease_id,
              payout,
              expenses,
              monthsCompleted,
              car,
              selloutReason,
            }),
          })
            .then((res) => {
              if (res.status == 200) {
                window.alert("Token Sellout successful");
              } else {
                window.alert("Token Sellout not successful");
              }
            })
            .then((itstime) => resetTheClock());
        }
      }
    }
  };

  const setUpload = () => {
    if (
      window.confirm(
        `Do you really want to set upload status true for the respective customer ?`
      )
    ) {
      fetch(`${API}/updateUploadStatus`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          lease_id: L,
        }),
      })
        .then((response) => resetTheClock());
    }
  };

  const statusChange = (status) => {
    if (
      window.confirm(
        `Do you really want to set deal ${status} for the respective car ?`
      )
    ) {
      fetch(`${API}/updateAppStatus`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          application_status: status,
          reason: reason,
          lease_id: L,
        }),
      })
        .then((response) => {
          handleClose1(false);
          approveText();
        })
        .then((itstime) => resetTheClock());
    }
  };

  function denyTeams() {
    fetch(`https://hooks.zapier.com/hooks/catch/7559792/bf9w38t/`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        message: `Inquiry number: ${pageInfo.inquiry_number} was denied`,
      }),
    });
  }
  function denyText() {
    fetch("https://hooks.zapier.com/hooks/catch/7559792/bf3y6ih/", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        phone: pageInfo.phone,
        message: `Unfortunately, your lease inquiry has been denied. Reason for decline: ${reason} `,
      }),
    })
  }

  function approveText() {
    fetch("https://hooks.zapier.com/hooks/catch/7559792/bf3y6ih/", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        phone: pageInfo.phone,
        message: "Congratulations! You have been approved for a lease. Please see your salesperson.",
      }),
    })
  }


  function dealStart() {

    var firstname = pageInfo.first_name 
    var car = pageInfo.year + " " + pageInfo.make + " " + pageInfo.model
      
        fetch(`${API}/dealStart`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            lease_id: L,
          }),
        })
          .then((res) => {
            if (res.status == 200) {
              window.alert("Deal Start Done!");
              fetch("https://hooks.zapier.com/hooks/catch/7559792/2o1ez0z/", {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({
                  Email: pageInfo.email,
                  FirstName: pageInfo.first_name,
                  YearMakeModel: car,
                  Totalmonthlypayment: pageInfo.monthly_payment,
                  Months: pageInfo.months,
                  Paydate: pageInfo.payment_date,
                  user_id: pageInfo.user_id,
                  start_date: pageInfo.start_date,
                  end_date: pageInfo.end_date,
                  VIN: pageInfo.vin,
                  xero_account_code:aum
                }),
              })
            } else {
              window.alert("Sorry there was an error.");
            }
          })
      
  }

  function dealEnd() {
    console.log(deal_end_reason, "deal end reason");
    fetch(`${API}/dealEnd`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        lease_id: L,
        deal_end_reason: deal_end_reason
      }),
    })
      .then((res) => {
        if (res.status == 200) {
          window.alert("Deal End Done!");
          window.location.replace(`https://accounts.google.com/v3/signin/identifier?dsh=S-787798621%3A1689792854838864&continue=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdd0vbVUFpsWJawDEs5fKCkP5ZWxJldfDCeBz_YU2djbsO-kQ%2Fviewform&followup=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdd0vbVUFpsWJawDEs5fKCkP5ZWxJldfDCeBz_YU2djbsO-kQ%2Fviewform&ifkv=AeDOFXjCH33oO3AfAf4vqYd6dp-QspuJHXFVYz1zA_-olY52hwGkryrayG-PsBZ-c9EFEvTZCUslew&ltmpl=forms&osid=1&passive=1209600&service=wise&flowName=GlifWebSignIn&flowEntry=ServiceLogin`);
        } else {
          window.alert("Sorry there was an error.");
        }
      })
  }


  const Tokenization = () => {
    if (pageInfo.deal_start_date == null) {
      window.alert(
        "Please do the deal start process first!"
      )
    }
    else {
      if (
        window.confirm(
          "Do you really want to initiate Tokenization for the respective car ?"
        )
      ) {
        if (window.confirm("Are you 100% sure?")) {
          fetch(`${API}/tokenize`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              final_price: pageInfo.final_price,
              buyout: pageInfo.buyout,
              prepayment: pageInfo.prepayment,
              start_date: pageInfo.start_date,
              end_date: pageInfo.end_date,
              lease_id: L,
              om_id: (num),
              makemodelyear: (pageInfo.year + " " + pageInfo.make + " " + pageInfo.model),
              vin: pageInfo.vin,
              odometer: pageInfo.odometer,
              car_status: pageInfo.car_status,
              monthly_payment: pageInfo.monthly_payment,
              markup: pageInfo.markup,
              residual: pageInfo.residual,
              sale_price: pageInfo.sale_price,
              months: pageInfo.months,
              lease_class: pageInfo.lease_class,
              permitted_km: pageInfo.permitted_km
            }),
          })
            .then((res) => {
              if (res.status == 200) {
                window.alert("Tokenization successful");
              } else {
                window.alert("Token Sellout not successful");
              }
            })
            .then((itstime) => resetTheClock());
        }
      }
    }
  };

  function notice(number) {
    var body = {};
    if (number == 3) {
      body = {
        First_Name: pageInfo.first_name,
        Last_Name: pageInfo.last_name,
        Email: pageInfo.email,
        Phone: pageInfo.phone,
        Address: pageInfo.street_address,
        City: pageInfo.city,
        Province: pageInfo.province,
        Country: pageInfo.country,
        postal_code: pageInfo.postal_code,
        Effective_Date: pageInfo.start_date,
        End_Date: pageInfo.end_date,
        PayDate: pageInfo.payment_date,
        missedmonth: missedmonth,
        notes: notes,
        Monthly_Payment: pageInfo.monthly_payment,
        Buyout_Price: pageInfo.buyout,
        Full_Price: pageInfo.final_price,
        Year: pageInfo.year,
        Make: pageInfo.make,
        Model: pageInfo.model,
        Colour: pageInfo.exterior_color,
        VIN: pageInfo.vin,
        Lisence: pageInfo.drivers_license,
      }
    }
    else {
      body = {
        First_Name: pageInfo.first_name,
        Last_Name: pageInfo.last_name,
        Email: pageInfo.email,
        Phone: pageInfo.phone,
        Address: pageInfo.street_address,
        City: pageInfo.city,
        Province: pageInfo.province,
        Country: pageInfo.country,
        postal_code: pageInfo.postal_code,
        Effective_Date: pageInfo.start_date,
        End_Date: pageInfo.end_date,
        PayDate: pageInfo.payment_date,
        missedmonth: missedmonth,
        Monthly_Payment: pageInfo.monthly_payment,
        Buyout_Price: pageInfo.buyout,
        Full_Price: pageInfo.final_price,
        Year: pageInfo.year,
        Make: pageInfo.make,
        Model: pageInfo.model,
        Colour: pageInfo.exterior_color,
        VIN: pageInfo.vin,
        Lisence: pageInfo.drivers_license,
      }
    }
    const queryString = Object.keys(body).map(key => key + '=' + body[key]).join('&');
    if (number == 0) {
      fetch(`https://hooks.zapier.com/hooks/catch/7559792/3yf9g9d/?${queryString}`, {
        method: "POST",
        mode: "cors",
      })
        .then(window.confirm('Notice sent'))
    }
    if (number == 1) {
      fetch(`https://hooks.zapier.com/hooks/catch/7559792/3yaohcd/?${queryString}`, {
        method: "POST",
        mode: "cors",
      })
        .then(window.confirm('Notice sent'))
    }
    if (number == 2) {
      fetch(`https://hooks.zapier.com/hooks/catch/7559792/3yg44dg/?${queryString}`, {
        method: "POST",
        mode: "cors",
      })
        .then(window.confirm('Notice sent'))
    }
    if (number == 3) {
      fetch(`https://hooks.zapier.com/hooks/catch/7559792/3yaolyf/?${queryString}`, {
        method: "POST",
        mode: "cors",
      })
        .then(window.confirm('Notice sent'))
    }

  }
// console.log(aum,"number")

  return (
    <div className="customerActions">
      <div className="carInfo">
        <div className="title">
          <h1>{pageInfo.year + " " + pageInfo.make + " " + pageInfo.model}</h1>
        </div>
        <hr />
        <div className="image">
          <img src={pageInfo.image.split(",")[0]} alt=""></img>
        </div>
        <div className="content">
          <p>
            License <b>{pageInfo.lic}</b>
          </p>
          <p>
            VIN <b>{pageInfo.vin}</b>
          </p>
          <p>
            Months Left{" "}
            <b>
              {contract &&
                Math.round(
                  moment(contract.end_date).diff(moment(), "months", true)
                )}
            </b>
          </p>
          <p>
            Effective date{" "}
            <b>
              {contract && moment(contract.start_date).format("DD/MM/YYYY")}
            </b>
          </p>
          <p>
            Maturity date{" "}
            <b>{contract && moment(contract.end_date).format("DD/MM/YYYY")}</b>
          </p>

          {pageInfo.leased_status != "Finished" &&
            pageInfo.leased_status != "Terminated" ? (
            <p>
              Current buyout <a target="_blank" href="https://docs.google.com/spreadsheets/d/1nxPSTw4e_xXuIBkYrD66NrDTy1tFUZlrKHSu9hzngfI/edit?usp=sharing"><b>Sheets</b></a>
            </p>
          ) : (
            " "
          )}
          <p>
            Monthly payments <b>$ {contract && contract.monthly_payment}</b>
          </p>
          <p>
            Exterior Color <b>{pageInfo.exterior_color}</b>
          </p>
          <p>
            Application Status{" "}
            <b style={{ color: "#702632" }}>
              {pageInfo && pageInfo.application_status}
            </b>
          </p>
          <p>
            Lease status<b>{pageInfo.leased_status}</b>
          </p>
        </div>
      </div>

      {/* ///////////////Tokenize and deal start////////////// */}
      {pageInfo && pageInfo.application_status == "Signed" ? (
        <div className="files">
          <div className="title">
            <h1>Lease Active!</h1>
          </div>
          <div className="buttons">
            <button
              style={{ width: "100%" }}
              onClick={() => {
                Tokenization();
              }}
            >
              Tokenize the lease
            </button>

            {
              pageInfo && !pageInfo.deal_start_date &&
              <button
                style={{ width: "100%" }}
                onClick={() => {
                  dealStart();
                }}
              >
                Deal Start
              </button>
            }

          </div>
        </div>
      ) : (
        <></>
      )}
      {/* ///////////////Tokenize and deal start ends here////////////// */}

      {/* ///////////////deal end////////////// */}
      {pageInfo && pageInfo.leased_status == "Terminated" ? (
        <div className="files">
          <div className="title">
            <h1>Lease End</h1>
          </div>
          <div className="buttons">
            <button
              style={{ width: "100%" }}
              onClick={() => {
                setOpen10(true)
              }}
            >
              Deal End
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* /////////////////////////////// deal end pop up starts here /////////////////////// */}
      <Dialog open={show10} onBackdropClick={handleClose10}>
        <div className="customSelloutPop">
          <div className="customSelloutTitle">
            <div className="title">Confirm Action</div>
            <button onClick={handleClose10}>X</button>
          </div>
          <div type="button" className="selectarea" aria-describedby={id1} onClick={handleClick2}>
            <div className="select butn">
              <div>{deal_end_reason}</div>
              <div className="dropdown">
                <img src={vectorIcon} alt=""></img>
              </div>
            </div>
          </div>

          {/* the dropdown starts here */}
          <Popover
            id={id2}
            open={open11}
            anchorEl={anchorEl2}
            onClose={handleClose11}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div>
              {reasonList2.map((status, i) => (
                <div
                  className="popoverText "
                  onClick={() => selectStatus2(status.status)}
                  key={i}
                >
                  {status.status}
                </div>
              ))}
            </div>
          </Popover>
          {/* the dropdown ends here */}

          <button
            type="button"
            className="cancel"
            onClick={() => {
              handleClose10();
              dealEnd();
            }}
          >
            Save and Proceed
          </button>
        </div>
      </Dialog>
      {/* /////////////////////////////// deal end pop up ends here /////////////////////// */}

      {/* ///////////////deal end  ends here////////////// */}


      {/* //////////////////////////payment notice buttons////////////////////////// */}
      {pageInfo && (pageInfo.application_status == "Tokenized" || pageInfo.application_status == "Signed") && pageInfo.leased_status != "Terminated" ? (
        <div className="contractOptions">
          <div className="title">
            <h1>Payment Notice Options</h1>
          </div>
          <div className="buttons">
            <button className="tokenSellout" style={{ opacity: "1" }}
              onClick={() => {
                setOpen5(true)
                setNumber(0)
              }}> Late payment</button>
            <button className="customSellout" style={{ opacity: "1" }}
              onClick={() => {
                setOpen5(true)
                setNumber(1)
              }}>
              More late payment
            </button>
          </div>
          <div className="buttons">
            <button className="tokenSellout" style={{ opacity: "1" }}
              onClick={() => {
                setOpen5(true)
                setNumber(2)
              }}>Final Notice</button>
            <button className="customSellout" style={{ opacity: "1" }}
              onClick={() => {
                setOpen7(true)
                setNumber(3)
              }}>
              Repo Notice
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      <Dialog open={show5} onBackdropClick={handleClose5}>
        <div className="customSelloutPop">
          <div className="customSelloutTitle">
            <div className="title">Confirm Action</div>
            <button onClick={handleClose5}>X</button>
          </div>
          <p>Please select the month for which the payment wasn't received</p>
          <div>
            <div type="button" className="selectarea" aria-describedby={id} onClick={handleClick}>
              <div className="select">
                <div className="butn" >{missedmonth}</div>
                <div className="dropdown">
                  <img src={vectorIcon} alt=""></img>
                </div>
              </div>
            </div>
            <Popover
              id={id}
              open={open6}
              anchorEl={anchorEl}
              onClose={handleClose6}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div>
                {monthList.map((status, key) => (
                  <div
                    className="popoverText "
                    onClick={() => selectStatus(status.status)}
                    key={key}
                  >
                    {status.status}
                  </div>
                ))}
              </div>
            </Popover>
          </div>
          <button
            type="button"
            className="cancel"
            onClick={() => {
              notice(number)
              handleClose5()
            }}
          >
            Send Notice
          </button>
        </div>
      </Dialog>

      <Dialog open={show7} onBackdropClick={handleClose7}>
        <div className="customSelloutPop">
          <div className="customSelloutTitle">
            <div className="title">Confirm Action</div>
            <button onClick={handleClose7}>X</button>
          </div>
          <input
            placeholder="Enter the notes for the Bailiff"
            onChange={(e) => setNotes(e.target.value)}
          ></input>
          <p>Please select the month for which the payment wasn't received</p>
          <div>
            <div type="button" className="selectarea" aria-describedby={id} onClick={handleClick}>
              <div className="select">
                <div className="butn" >{missedmonth}</div>
                <div className="dropdown">
                  <img src={vectorIcon} alt=""></img>
                </div>
              </div>
            </div>
            <Popover
              id={id}
              open={open6}
              anchorEl={anchorEl}
              onClose={handleClose6}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div>
                {monthList.map((status, key) => (
                  <div
                    className="popoverText "
                    onClick={() => selectStatus(status.status)}
                    key={key}
                  >
                    {status.status}
                  </div>
                ))}
              </div>
            </Popover>
          </div>
          <button
            type="button"
            className="cancel"
            onClick={() => {
              notice(3)
              handleClose7()
            }}
          >
            Send Notice
          </button>
        </div>
      </Dialog>




      {pageInfo && pageInfo.application_status == "Tokenized" ? (
        <div className="contractOptions">
          <div className="title">
            <h1>Contract Options</h1>
          </div>
          <div className="buttons">
            <a
              className="viewContract"
              style={{ width: "100%" }}
              href={`https://econommi.sharepoint.com/sites/operations/Shared%20Documents/operations/FAPP/Users/${pageInfo.email
                }/${pageInfo.vin}/LA-${pageInfo.payment_date
                }-${pageInfo.vin.substring(pageInfo.vin.length - 4)}-1.pdf`}
              target="_blanck"
            >
              <button style={{ width: "100%" }}>View Contract</button>
            </a>
            <button className="updateContract" style={{ display: "none" }}>
              Update Contract
            </button>
            <a
              className="amendLOA"
              style={{ display: "none" }}
              href={`https://econommi.sharepoint.com/sites/operations/Shared%20Documents/operations/FAPP/Users/${pageInfo.email}/${pageInfo.vin}/LOA-1.pdf`}
              target="_blanck"
            >
              <button style={{ width: "100%" }}>Amend LOA</button>
            </a>
            {pageInfo.leased_status != "Finished" &&
              pageInfo.leased_status != "Terminated" ? (
              <>
                <button
                  className="tokenSellout"
                  disabled={disabled}
                  style={disabled ? { opacity: "0.4" } : { opacity: "1" }}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Do you really want to initiate Token Sellout for the respective car ?"
                      )
                    ) {
                      if (window.confirm("Are you 100% sure?")) {
                        setOpen8(true);
                      }
                    }
                  }}
                >
                  Token sellout
                </button>

                <button
                  className="customSellout"
                  disabled={disabled}
                  style={disabled ? { opacity: "0.4" } : { opacity: "1" }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Custom sellout
                </button>
              </>
            ) : (
              ""
            )}


            {/* the dailog box the token sellout starts here */}
            <Dialog open={show8} onBackdropClick={handleClose8}>
              <div className="customSelloutPop">
                <div className="customSelloutTitle">
                  <div className="title">Confirm Action</div>
                  <button onClick={handleClose8}>X</button>
                </div>
                <div type="button" className="selectarea" aria-describedby={id1} onClick={handleClick1}>
                  <div className="select butn">
                    <div>{selloutReason}</div>
                    <div className="dropdown">
                      <img src={vectorIcon} alt=""></img>
                    </div>
                  </div>
                </div>

                {/* the dropdown starts here */}
                <Popover
                  id={id1}
                  open={open9}
                  anchorEl={anchorEl1}
                  onClose={handleClose9}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div>
                    {reasonList1.map((status, i) => (
                      <div
                        className="popoverText "
                        onClick={() => selectStatus1(status.status)}
                        key={i}
                      >
                        {status.status}
                      </div>
                    ))}
                  </div>
                </Popover>
                {/* the dropdown ends here */}

                <button
                  type="button"
                  className="cancel"
                  onClick={() => {
                    tokenSellout();
                    handleClose8();
                    // console.log(reason);
                  }}
                >
                  Save and Proceed
                </button>
              </div>
            </Dialog>
            {/* the dailog box the token sellout ends here */}


            <Dialog open={show} onBackdropClick={handleClose}>
              <div className="customSelloutPop">
                <div className="customSelloutTitle">
                  <div className="title">Confirm Action</div>
                  <button onClick={handleClose}>X</button>
                </div>
                <input
                  placeholder=" Please enter total expenses like towing fees, etc"
                  onChange={(e) => setExpenses(e.target.value)}
                ></input>
                <input
                  placeholder="Enter the ICBC payout"
                  onChange={(e) => setPayout(e.target.value)}
                ></input>

                <div type="button" className="selectarea" aria-describedby={id1} onClick={handleClick1}>
                  <div className="select butn">
                    <div>{selloutReason}</div>
                    <div className="dropdown">
                      <img src={vectorIcon} alt=""></img>
                    </div>
                  </div>
                </div>

                {/* the dropdown starts here */}
                <Popover
                  id={id1}
                  open={open9}
                  anchorEl={anchorEl1}
                  onClose={handleClose9}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div>
                    {reasonList1.map((status, i) => (
                      <div
                        className="popoverText "
                        onClick={() => selectStatus1(status.status)}
                        key={i}
                      >
                        {status.status}
                      </div>
                    ))}
                  </div>
                </Popover>
                {/* the dropdown ends here */}

                <button
                  type="button"
                  className="cancel"
                  onClick={() => {
                    handleClose();
                    customSellOut();
                  }}
                >
                  Save and Proceed
                </button>
              </div>
            </Dialog>

            <button
              className="unlockSellout"
              onClick={() => setDisabled(false)}
            >
              UNLOCK SELLOUT
            </button>
            <a
              className="newLOA"
              href={`https://docs.google.com/forms/d/e/1FAIpQLScxVdTFaKKAGbNwqzCy5t1-7oTEBrRBzwyIG9iqPn11nGAI4w/viewform`}
              target="_blanck"
            >
              <button style={{ width: "100%" }}>New LOA</button>
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
      {pageInfo && pageInfo.application_status == "Signed" ? (
        <div className="emailTemplates">
          <div className="title">
            <h1>Message templates</h1>
          </div>
          <div className="buttons">
            <>
              <button
                className="missedPayment"
                onClick={() => {
                  sendMessage1();
                }}
              >
                Missed Payment Notice
              </button>
              <button
                className="repo"
                onClick={() => {
                  sendMessage2();
                }}
              >
                Repo Notice
              </button>
            </>
          </div>
        </div>
      ) : (
        <></>
      )}

      {pageInfo && pageInfo.application_status == "In Progress" ? (
        <div className="emailTemplates">
          <div className="title">
            <h1>Document Upload</h1>
          </div>
          <div className="buttons">
            <>
              <button
                className="missedPayment"
                onClick={() => {
                  setUpload();
                }}
              >
                Skip Document Upload
              </button>
            </>
          </div>
        </div>
      ) : (<></>)}

      {pageInfo && pageInfo.application_status == "Pending" ? (
        <div
          className="emailTemplates"
          style={{ display: opne2 == true ? "block" : "none" }}
        >
          <div className="title">
            <h1>New Deal !</h1>
          </div>
          <div className="buttons">
            <>
              <button
                className="missedPayment"
                onClick={() => {
                  setOpen1(true);
                  setOpen2(false);
                }}
              >
                View Inquiry
              </button>
            </>
            {/*  <button className="blankTemplate">
                        Blank template
                    </button>
                    <input placeholder='jkkj'></input>*/}
          </div>
        </div>
      ) : (
        <></>
      )}


      <div
        className="withdrawalRequest"
        style={{ display: opne1 == true ? "block" : "none" }}
      >
        <div className="title">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Inquiry Details
            <button
              onClick={() => {
                handleClose1(false);
                handleClose2(true);
              }}
            >
              X
            </button>
          </h1>
        </div>
        <>
          <div className="content">
          <div className="status">
              <p>Initial Price</p>
              <p>{pageInfo.initial_price}</p>
            </div>
            <div className="status">
              <p>Max. Retail Price</p>
              <p>{Math.round(pageInfo.retail_price_usd * (fxRate+0.1))}</p>
            </div>
            <div className="status">
              <p>Present Value</p>
              <p>{pageInfo.present_value}</p>
            </div>
            <div className="status">
              <p>Max. WholeSale Price</p>
              <p>{Math.round(pageInfo.wholesale_price_usd * (fxRate+0.1))}</p>
            </div>
            <br></br>
            <div className="status">
              <p>Min. Prepayment</p>
              <p>{getMinPrepayment(pageInfo.credit_score,pageInfo.initial_price,pageInfo.lease_class,pageInfo.warranty,pageInfo.doc_fee,pageInfo.trade_in,Math.round(pageInfo.wholesale_price_usd*(fxRate+0.1)))}</p>
            </div>
            <div className="status">
              <p>Prepayment</p>
              <p>{pageInfo.prepayment}</p>
            </div>
            <div className="status">
              <p>Prepayment Tax</p>
              <p>{pageInfo.prepayment_tax}</p>
            </div>
            <div className="status">
              <p>Credit Score</p>
              <p>{pageInfo.credit_score}</p>
            </div>
            <br></br>
            <div className="status">
              <p>Monthly Income</p>
              <p>{pageInfo.monthly_income}</p>
            </div>
            <div className="status">
              <p>Employment Status</p>
              <p>{pageInfo.employment_status}</p>
            </div>
            <div className="status">
              <p>Monthly Payment</p>
              <p>{pageInfo.monthly_payment}</p>
            </div>
            <br></br>
            <div className="status">
              <p>Months</p>
              <p>{pageInfo.months}</p>
            </div>
            <div className="status">
              <p>Trade In</p>
              <p>{pageInfo.trade_in}</p>
            </div>
            <div className="status">
              <p>Warranty</p>
              <p>{pageInfo.warranty}</p>
            </div>
            <div className="status">
              <p>Doc Fee</p>
              <p>{pageInfo.doc_fee}</p>
            </div>
            <div className="status">
              <p>Residual</p>
              <p>{pageInfo.residual}</p>
            </div>
            <div className="status">
              <p>econommi Fee</p>
              <p>{pageInfo.carmodity_fee}</p>
            </div>
            <div className="status">
              <p>Final Price</p>
              <p>{pageInfo.final_price}</p>
            </div>
            <div className="status">
              <p>Capitalized Amount</p>
              <p>{pageInfo.capitalized_amt}</p>
            </div>
            <div className="status">
              <p>Fixed Fee (prev. Markup)</p>
              <p>{pageInfo.markup}</p>
            </div>
            <div className="status">
              <p>Lease Payment</p>
              <p>{pageInfo.lease_cost}</p>
            </div>
            <div className="status">
              <p>Lease Taxes</p>
              <p>{pageInfo.lease_taxes}</p>
            </div>
            <div className="status">
              <p>Permitted KM</p>
              <p>{pageInfo.permitted_km}</p>
            </div>
            <div className="status">
              <p>Lease Class</p>
              <p>{pageInfo.lease_class}</p>
            </div>
          </div>
          <div className="buttons">
            <button
              className="denyButton"
              onClick={() => {
                setOpen4(true);
              }}
            >
              DENY
            </button>
            <button
              className="approveButton"
              onClick={() => {
                statusChange("Approved");
              }}
            >
              APPROVE
            </button>
          </div>
        </>
      </div>
      <Dialog open={show4} onBackdropClick={handleClose4}>
        <div className="customSelloutPop">
          <div className="customSelloutTitle">
            <div className="title">Confirm Action</div>
            <button onClick={handleClose4}>X</button>
          </div>
          <input
            placeholder=" Please enter the reason for denying the respective deal"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          ></input>
          <button
            type="button"
            className="cancel"
            onClick={() => {
              statusChange("Declined");
              handleClose4();
              // console.log(reason);
              denyTeams();
              denyText();
            }}
          >
            Save and Proceed
          </button>
        </div>
      </Dialog>

      <div className="files">
        <div className="title">
          <h1>Files</h1>
        </div>
        <div className="buttons">
          <a
            className="viewContract"
            style={{ width: "100%" }}
            href={`https://econommi.sharepoint.com/:f:/s/operations/EvS0YuqWRGhDqSwaW9YOLx0B15KkOSUPpJYxTx2zeCrZbg?e=duASbN`}
            target="_blanck"
          >
            <button>Open in SharePoint</button>
          </a>
          <button
            className="copyLink"
            style={{ width: "100%" }}
            onClick={() =>
              navigator.clipboard.writeText(
                `https://econommi.sharepoint.com/:f:/s/operations/EvS0YuqWRGhDqSwaW9YOLx0B15KkOSUPpJYxTx2zeCrZbg?e=duASbN`
              )
            }
          >
            Copy Link
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerAction;
