import React, { useState, useEffect } from "react";
import "./User.scss";
import CustomerInfo from "./CustomerInfo";
import CustomerAction from "./CustomerAction";
import { useUserInfo, useUserInfoUpdate } from "../../../Context/UserContext";
import moment from "moment";
import ConfirmBox from "./ConfirmBox.jsx";
import { DialogContentText } from "@mui/material";
const API = process.env.REACT_APP_API_URL;

function User() {
  const context = useUserInfo();
  const resetTheClock = useUserInfoUpdate();
  const [viewAsset, setViewAsset] = useState({ id: "1", status: "Active" });
  const [activeSection, setActiveSection] = useState("actions");
  const [L, setL] = useState();
  const [note, setNote] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [pageUserInfo, setPageUserInfo] = useState();
  const [history, setHistory] = useState();
  const [leasePaymenthistory, setleasePaymenthistory] = useState();
  const [payments, setPayments] = useState();
  const [editing, setEditing] = useState(false);
  const [editing2, setEditing2] = useState(false);
  const [editing3, setEditing3] = useState(false);
  const [editing4, setEditing4] = useState(false);
  const [first_name, setFirstName] = useState();
  const [last_name, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [monthsCompleted, setMonthsCompleted] = useState();
  const [userId, setUserId] = useState();
  const [city, setCity] = useState("");
  const [street_address, setStreet_address] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("");
  const [postal_code, setPostalCode] = useState();
  const [historyEntry, setHistoryEntry] = useState();
  const [contract, setContract] = useState();
  const [buyout, setBuyout] = useState(0);
  const [historyClock, setHistoryClock] = useState(false);
  const [email, setEmail] = useState();
  const [tracker_number, setTrackerNumber] = useState();
  const [license_plate, setlicensePlate] = useState();
  const [registration, setregistration] = useState();
  const [registration_province, setRegistrationProvince] = useState();
  const [address, setAddress] = useState();
  const [referenceCode, setReferenceCode] = useState();
  const [num, setNum] = useState();
  const [insurance, setInsurance] = useState();
  const [carID, setcarID] = useState();
  const [fxRate, setFxRate] = useState(0);

  useEffect(() => {
    context && setL(context.params.get("l"));
  }, [context.params]);
  var theone;
  // var thecar;
  useEffect(() => {

    let date = moment().format('YYYY-MM-DD')
    fetch(`https://www.bankofcanada.ca/valet/observations/FXUSDCAD/json?start_date=${date}&end_date=${date}`, {
      method: "GET",
      mode: "cors",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.observations[0]) {
          setFxRate(parseFloat(result.observations[0].FXUSDCAD.v))
        }
        else {
          console.log("default")
          setFxRate(1.36)
        }
      })

    // context && console.log(context.userInfo.deals, '=========contextuserinfoassets=========')
    // context && console.log(context.userInfo.assets.filter((lease) => lease.lease_id == L), '=========contextuserinfoassets=========')
    // context && console.log(context,"bvbjbjv");
    // context && context.userInfo && context.userInfo.om_id && setNum(context.userInfo.om_id[0].max+1);
    if (context && context.userInfo && context.userInfo.deals) {
      // console.log(L,'=================leaseID')
      theone = context.userInfo.deals.filter((lease) => lease.lease_id == L)
      // thecar = context.userInfo.assets.filter((lease) => lease.lease_id == L)
    }
    // ? (theone = context.userInfo.deals.filter((lease) => lease.lease_id == L))
    // : console.log(""); //we are the lessee using lease id.
    var theuser;
    context && context.userInfo && context.userInfo.deals
      ? (theuser = context.userInfo.deals.filter(
        (lease) => lease.lease_id == L
      ))
      : console.log(""); //we are the lessee using lease id.
    // context && context.userInfo && context.userInfo.assets
    //   ? (thecar = context.userInfo.assets.filter((lease) => lease.lease_id == L))
    //   : console.log(""); //we are the lessee using lease id.

    theone &&
      theone[0] &&
      context &&
      context.userInfo &&
      context.userInfo.deals &&
      setPageInfo(theone[0]);
    theuser &&
      theuser[0] &&
      context &&
      context.userInfo &&
      context.userInfo.deals &&
      setPageUserInfo(theuser[0]);
    theuser &&
      theuser[0] &&
      context &&
      context.userInfo &&
      context.userInfo.deals &&
      setUserId(theuser[0].user_id);

    theone &&
      theone[0] &&
      context &&
      context.userInfo &&
      context.userInfo.deals &&
      setcarID(theone[0].car_id);
    // console.log(theone, "nnnjjcjn");
    // console.log(pageUserInfo, "page User Info");
    function insurance() {
      var deets;
      deets = context.userInfo.insurance.filter((entry) => entry.car_id == theone[0].car_id);
      setInsurance(deets[0]);
    }
    function getAllInfo(id) {
      fetch(`${API}/history/${userId}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((history) => setHistory(history));


      fetch(`${API}/getleasePaymenthistory/${id}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((leasePaymenthistory) => setleasePaymenthistory(leasePaymenthistory));



      fetch(`${API}/getPaymentsByLeaseID/${id}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((payments) => setPayments(payments));

      fetch(`${API}/getNotes/${id}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((note) => { setNote(note) });

      fetch(`${API}/getContract/${id}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((contracts) => {
          let contract = contracts[0];
          // console.log(contract,"vhchvh")
          const monthsCompleted = Math.floor(
            moment().diff(moment(contract.start_date), "months", true)
          );
          const feeRemaining =
            contract.carmodity_fee -
            (monthsCompleted / contract.months) * contract.carmodity_fee;
          const taxRemaining =
            contract.prepayment_tax -
            (monthsCompleted / contract.months) * contract.prepayment_tax;
          const warrantyRemaining =
            contract.doc_fee -
            (monthsCompleted / contract.months) * contract.doc_fee;
          const docFeeRemaining =
            contract.warranty -
            (monthsCompleted / contract.months) * contract.warranty;
          // console.log(
          //   feeRemaining,
          //   taxRemaining,
          //   warrantyRemaining,
          //   docFeeRemaining
          // );
          const amountOwed =
            taxRemaining +
            (feeRemaining +
              warrantyRemaining +
              docFeeRemaining +
              contract.amount_overdue +
              contract.principle_remaining) *
            1.12;
          setBuyout(amountOwed);
          setMonthsCompleted(monthsCompleted);
          setContract(contract);
        });
    }

    theone &&
      theone[0] &&
      theuser &&
      theuser[0] &&
      userId &&
      context.userInfo &&
      context.userInfo.deals &&
      getAllInfo(L);

    theone &&
      theone[0] &&
      carID &&
      context.userInfo &&
      context.userInfo.insurance &&
      insurance(carID);
  }, [context.userInfo, L, userId, carID, historyClock]);


  const startEditing = () => {
    setEditing(true);
    pageUserInfo.first_name != "NaN" && setFirstName(pageUserInfo.first_name);
    pageUserInfo.last_name != "NaN" && setLastName(pageUserInfo.last_name);
    pageUserInfo.phone != "NaN" && setPhone(pageUserInfo.phone);
    pageUserInfo.email != "NaN" && setEmail(pageUserInfo.email);
    pageUserInfo.referenceCode != "NaN" &&
      setReferenceCode(pageUserInfo.referral_link);
    pageUserInfo.postal_code != "NaN" &&
      setPostalCode(pageUserInfo.postal_code);
  };

  const startEditing2 = () => {
    historyEntry == " "
      ? setHistoryEntry("History entry empty")
      : setEditing2(true);
  };

  const startEditing4 = () => {
    setEditing3(true);
    insurance.license_plate != "NaN" && setlicensePlate(insurance.license_plate);
    insurance.registration != "NaN" && setregistration(insurance.registration);
    insurance.registration_province != "NaN" && setRegistrationProvince(insurance.registration_province);
  };

  const startEditing5 = () => {
    setEditing4(true);
    pageUserInfo.tracker_number != "NaN" && setlicensePlate(pageUserInfo.tracker_number);
  };

  const changeUserInfo = () => {
    setEditing(false);

    if (
      pageUserInfo.first_name == "" ||
      pageUserInfo.last_name == "" ||
      pageUserInfo.phone == "" ||
      pageUserInfo.postal_code == "" ||
      pageUserInfo.street_address == "" ||
      pageUserInfo.city == "" ||
      pageUserInfo.country == ""
    ) {
      window.alert("Please fill in everything!");
    } else {
      fetch(`${API}/updateUserCore`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
        body: JSON.stringify({
          first_name: first_name === "" ? pageUserInfo.first_name : first_name,
          last_name: last_name === "" ? pageUserInfo.last_name : last_name,
          phone: phone === "" ? pageUserInfo.phone : phone,
          street_address: street_address === "" ? pageUserInfo.street_address : street_address,
          city: city === "" ? pageUserInfo.city : city,
          province: province === "" ? pageUserInfo.province : province,
          country: country === "" ? pageUserInfo.country : country,
          postal_code: postal_code === "" ? pageUserInfo.postal_code : postal_code,
          user_id: pageUserInfo.user_id,
        }),
      })
        .then((res) => window.confirm("Update successful"))
        .then((itstime) => resetTheClock());
    }
  };

  const changeInsuranceInfo = () => {
    setEditing3(false);

    if (
      license_plate == "" ||
      registration == "" ||
      registration_province == ""
    ) {
      window.alert("Please fill in everything!");
    } else {
      fetch(`${API}/updateInsuranceCore`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
        body: JSON.stringify({
          license_plate: license_plate === "" ? insurance.license_plate : license_plate,
          registration: registration === "" ? insurance.registration : registration,
          registration_province: registration_province === "" ? insurance.registration_province : registration_province,
          car_id: insurance.car_id,
        }),
      })
        .then((res) => window.confirm("Update successful"))
        .then((itstime) => resetTheClock());
    }
  };

  const updateTrackerNumber = () => {
    setEditing4(false);
    if (
      tracker_number == "NaN" || tracker_number == "" || tracker_number == " " || tracker_number == null
    ) {
      window.alert("Please fill in tracker number!");
    } else {
      console.log(tracker_number, "the tracker number")
      fetch(`${API}/updateTrackerNumber`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
        body: JSON.stringify({
          tracker_number: tracker_number === "" ? pageUserInfo.tracker_number : tracker_number,
          lease_id: pageUserInfo.lease_id,
        }),
      })
        .then((res) => window.confirm("Update successful"))
        .then((itstime) => resetTheClock());
    }
  };


  const missedPaymentNotice = () => {
    if (window.confirm("Do you want to send this message ?")) {
      fetch(`https://hooks.zapier.com/hooks/catch/7559792/bf3y6ih/`, {
        method: "POST",
        mode: "cors",

        body: JSON.stringify({
          phone: pageUserInfo.phone,
          message:
            "Hello, this is Carmodity. You missed this month's payment please kindly, complete payment or call us at 7786557966 to notify us of your situation.",
        }),
      })
        .then((response) => response.json())
        .then(window.confirm("Message Sent"))
        .then((response) => {
          fetch(`${API}/addHistoryEntry`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${context.accessToken}`,
            },
            body: JSON.stringify({
              verb: "Missed Payment Notice Sent",
              lease_id: pageInfo.lease_id,
              user_id: userId,
            }),
          });
        })
        .then((response) => setHistoryClock((whatever) => !whatever));
    }
  };
  const repoNotice = () => {
    if (window.confirm("Do you want to send this message ?")) {
      fetch(`https://hooks.zapier.com/hooks/catch/7559792/bf3y6ih/`, {
        method: "POST",
        mode: "cors",

        body: JSON.stringify({
          phone: pageUserInfo.phone,
          message:
            "Hello! You are overdue on payments and past your grace period. Your vehicle will be repossessed. Call 7786557966 in 24 hours to prevent this action. -Carmodity",
        }),
      })
        .then((response) => response.json())
        .then(window.confirm("Message sent"))
        .then((response) => {
          fetch(`${API}/addHistoryEntry`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${context.accessToken}`,
            },
            body: JSON.stringify({
              verb: "Reposession Notice sent",
              lease_id: pageInfo.lease_id,
              user_id: userId,
            }),
          });
        })
        .then((response) => setHistoryClock((whatever) => !whatever));
    }
  };

  const addHistoryEntry = () => {
    setEditing2(false);
    if (historyEntry != "" && historyEntry != " ") {
      fetch(`${API}/addHistoryEntry`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
        body: JSON.stringify({
          verb: historyEntry,
          lease_id: pageInfo.lease_id,
          user_id: userId,
        }),
      })
        .then((res) => window.confirm("Update successful"))
        .then((res) => setHistoryClock((whatever) => !whatever))
        .then((res) => setHistoryEntry(""));
    } else {
      window.alert("empty history entry");
      setHistoryEntry("");
    }
  };

  const updateActiveSection = (sectionName) => {
    setActiveSection(sectionName);
  };

  const active = (tabName) => {
    if (tabName === activeSection) return "active";
    else return "";
  };


  return (
    <div className="userpage">
      <div className="pageTitle">
        <p>LEASED CAR INFORMATION - BY CUSTOMER</p>
        {pageInfo && (
          <div className="pageHeader">
            {pageUserInfo.first_name + " " + pageUserInfo.last_name}
          </div>
        )}
      </div>
      <div className="mobileButton">
        <div className="buttonContent">
          <button
            className={`${active("actions")}`}
            onClick={() => updateActiveSection("actions")}
          >
            Actions
          </button>
          <button
            className={`${active("info")}`}
            onClick={() => updateActiveSection("info")}
          >
            Info
          </button>
        </div>
      </div>
      {/* For Web View */}
      <div className="pageContent">
        {pageInfo &&
          pageUserInfo &&
          fxRate &&
          contract &&
          context &&
          payments &&
          history &&
          leasePaymenthistory &&
          userId && (
            <>
              <CustomerAction
                buyout={buyout}
                monthsCompleted={monthsCompleted}
                pageInfo={pageInfo}
                fxRate={fxRate}
                aum={context.userInfo.aum}
                pageUserInfo={pageUserInfo}
                contract={contract}
                missedPaymentNotice={missedPaymentNotice}
                repoNotice={repoNotice}
                accessToken={context.accessToken}
                num={num}
                L={L}
              />
              <CustomerInfo
                contract={contract}
                pageUserInfo={pageUserInfo}
                pageInfo={pageInfo}
                history={history}
                leasePaymenthistory={leasePaymenthistory}
                addHistoryEntry={addHistoryEntry}
                historyEntry={historyEntry}
                setHistoryEntry={setHistoryEntry}
                editing2={editing2}
                startEditing2={startEditing2}
                payments={payments}
                userId={userId}
                setFirstName={setFirstName}
                first_name={first_name}
                last_name={last_name}
                setLastName={setLastName}
                phone={phone}
                setPhone={setPhone}
                postal_code={postal_code}
                setPostalCode={setPostalCode}
                street_address={street_address}
                setStreet_address={setStreet_address}
                city={city}
                setCity={setCity}
                province={province}
                setProvince={setProvince}
                country={country}
                setCountry={setCountry}
                changeUserInfo={changeUserInfo}
                changeInsuranceInfo={changeInsuranceInfo}
                updateTrackerNumber={updateTrackerNumber}
                editing={editing}
                editing3={editing3}
                editing4={editing4}
                startEditing={startEditing}
                startEditing4={startEditing4}
                startEditing5={startEditing5}
                email={email}
                setEmail={setEmail}
                license_plate={license_plate}
                tracker_number={tracker_number}
                setlicensePlate={setlicensePlate}
                setTrackerNumber={setTrackerNumber}
                registration={registration}
                registration_province={registration_province}
                setRegistrationProvince={setRegistrationProvince}
                setregistration={setregistration}
                referral_link={referenceCode}
                note={note}
                insurance={insurance}
              />
            </>
          )}
      </div>
      {/* For Mobile View */}
      <div className="pageContentMobile">
        {activeSection === "actions"
          ? pageInfo &&
          contract &&
          pageUserInfo &&
          context &&
          payments &&
          history && (
            <CustomerAction
              buyout={buyout}
              monthsCompleted={monthsCompleted}
              pageInfo={pageInfo}
              aum={context.userInfo.aum}
              pageUserInfo={pageUserInfo}
              contract={contract}
              missedPaymentNotice={missedPaymentNotice}
              repoNotice={repoNotice}
              accessToken={context.accessToken}
              L={L}
            />
          )
          : pageInfo &&
          contract &&
          pageUserInfo &&
          payments &&
          userId &&
          history &&
          leasePaymenthistory &&
          userId && (
            <CustomerInfo
              pageUserInfo={pageUserInfo}
              contract={contract}
              pageInfo={pageInfo}
              history={history}
              leasePaymenthistory={leasePaymenthistory}
              addHistoryEntry={addHistoryEntry}
              historyEntry={historyEntry}
              setHistoryEntry={setHistoryEntry}
              editing2={editing2}
              startEditing2={startEditing2}
              payments={payments}
              userId={userId}
              setFirstName={setFirstName}
              first_name={first_name}
              last_name={last_name}
              setLastName={setLastName}
              phone={phone}
              setPhone={setPhone}
              postal_code={postal_code}
              setPostalCode={setPostalCode}
              street_address={street_address}
              setStreet_address={setStreet_address}
              city={city}
              setCity={setCity}
              province={province}
              setProvince={setProvince}
              country={country}
              setCountry={setCountry}
              changeUserInfo={changeUserInfo}
              changeInsuranceInfo={changeInsuranceInfo}
              updateTrackerNumber={updateTrackerNumber}
              editing={editing}
              editing3={editing3}
              editing4={editing4}
              startEditing={startEditing}
              startEditing4={startEditing4}
              startEditing5={startEditing5}
              email={email}
              setEmail={setEmail}
              license_plate={license_plate}
              tracker_number={tracker_number}
              setlicensePlate={setlicensePlate}
              setTrackerNumber={setTrackerNumber}
              registration={registration}
              setregistration={setregistration}
              registration_province={registration_province}
              setRegistrationProvince={setRegistrationProvince}
              note={note}
              insurance={insurance}
            />
          )}
      </div>
    </div>
  );
}

export default User;
