import React, { useState, useEffect } from "react";
import "./ActiveCoreTab.scss";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
//Importing images
import userImage from "../../assets/userImage.png";
import { useUserInfo } from "../../Context/UserContext";

function ActiveCoreTab() {
  const location = useLocation();
  const context = useUserInfo();
  const [Info, setInfo] = useState();
  const activePath = location.pathname.split("/")[1];
  const [activeBar, setActiveBar] = useState("Operations");

  const defActive = () => (activePath === "" ? "active" : "");

  useEffect(() => {
   context && context.user && setInfo(context.user);
    //we are the lessee using lease id.
  }, [context.user]);



  useEffect(() => {
    if (
      activePath === "operations" ||
      activePath === "User" ||
      activePath === "platform" ||
      activePath === "UserName" ||
      activePath === "EditAsset"
    ) {
      //   setActiveBar("Operations");
      setActiveBar("Users");
    } else if (
      activePath === "dealers" ||
      activePath === "AddDealer" ||
      activePath === "Dealership" ||
      activePath === "Deals"
    ) {
      setActiveBar("Dealership");
    }
    // else if (
    //   // activePath === "platform" ||
    //   // activePath === "UserName" ||
    //   // activePath === "EditAsset"
    // ) {
    //   setActiveBar("Platform");
    else if (activePath === "interfaces") {
      //   setActiveBar("Interfaces");
      setActiveBar("Controls");
    } else if (activePath === "notifications") {
      setActiveBar("Notifications");
    } else setActiveBar("Users");

    return null;
  }, [activePath]);

  return (
    <div className="activeCoreTab">
      <div className="topTabWeb">
        <Link to="/">
          <h2>ActiveCore</h2>
        </Link>
      </div>
      <div className="contentTab">
        <div className="midTab">
          <div className="userTab">
            <div className="userImage">
              {/* {context.user && Info && (
                <img
                  className="photo"
                  src={`https://www.tinygraphs.com/spaceinvaders/${context.user.email}?theme=base&numcolors=4&size=220&fmt=svg`}
                />
              )} */}
            </div>
            <div className="userDescription">
              {context && Info ? (
                <>
                  {" "}
                  <div className="userName">
                    {Info.name}
                  </div>
                  <div className="email">{Info.email}</div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="userName">user name</div>
                  <div className="email">user email</div>
                </>
              )}
            </div>
          </div>
          <div className="tabOptions">
            {/* <div className={activeBar == 'Operations' ? ('active tabDiv') : ('tabDiv')}>
                            <Link to="/operations" >
                                <button>
                                    <div className="tabTitle">Operations</div>
                                </button>
                            </Link>
                        </div> */}
            <div className={activeBar == "Users" ? "active tabDiv" : "tabDiv"}>
              <Link to="/operations">
                <button>
                  <div className="tabTitle">Users</div>
                </button>
              </Link>
            </div>
            <div
              className={activeBar == "Dealership" ? "active tabDiv" : "tabDiv"}
            >
              <Link to="/dealers">
                <button>
                  <div className="tabTitle">Dealers</div>
                </button>
              </Link>
            </div>
            {/* <div
              className={activeBar == "Platform" ? "active tabDiv" : "tabDiv"}
            >
              <Link to="/platform">
                <button>
                  <div className="tabTitle">Platform</div>
                </button>
              </Link>
            </div> */}
            {/* <div className={activeBar == 'Interfaces' ? ('active tabDiv') : ('tabDiv')}>
                            <Link to="/interfaces">
                                <button>
                                    <div className="tabTitle">Interfaces</div>
                                </button>
                            </Link>
                        </div> */}
            <div
              className={
                activeBar == "Notifications" ? "active tabDiv" : "tabDiv"
              }
            >
              <Link to="/notifications">
                <button>
                  <div className="tabTitle">Notifications</div>
                </button>
              </Link>
            </div>
            <div
              className={activeBar == "Controls" ? "active tabDiv" : "tabDiv"}
            >
              <Link to="/interfaces">
                <button>
                  <div className="tabTitle">Controls</div>
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="bottomTab">
          <div className="dateInfo">
            <p>{moment().format("h:mm:ss A")}</p>
            <p>{moment().format("MMMM DD, YYYY")}</p>
          </div>
          <div className="logoutButton">
            <button onClick={() => context.logout()}>LOG OUT</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveCoreTab;
