import React, { useState, useEffect } from "react";
import "./User.scss";
import checker from "../../../assets/checker.png";
import polygon from "../../../assets/Polygon.png";
import Popover from "@mui/material/Popover";
import Dialog from "@mui/material/Dialog";
import plus from "../../../assets/Plus.png";
import moment from "moment";
import { useUserInfo, useUserInfoUpdate } from "../../../Context/UserContext";
import { Link, useLocation } from "react-router-dom";
import vector from '../../../assets/vectorarrow.png';
const API = process.env.REACT_APP_API_URL;

const CustomerInfo = (props) => {
  const {
    pageInfo,
    pageUserInfo,
    history,
    leasePaymenthistory,
    payments,
    addHistoryEntry,
    historyEntry,
    setHistoryEntry,
    editing2,
    startEditing2,
    userId,
    setFirstName,
    first_name,
    last_name,
    setLastName,
    phone,
    setPhone,
    postal_code,
    setPostalCode,
    street_address,
    setStreet_address,
    city,
    setCity,
    province,
    setProvince,
    country,
    setCountry,
    setUserId,
    email,
    setEmail,
    address,
    setAddress,
    referenceCode,
    setReferenceCode,
    changeUserInfo,
    changeInsuranceInfo,
    updateTrackerNumber,
    editing,
    editing3,
    editing4,
    license_plate,
    tracker_number,
    setTrackerNumber,
    setlicensePlate,
    registration,
    setregistration,
    registration_province,
    setRegistrationProvince,
    startEditing,
    startEditing4,
    startEditing5,
    note,
    insurance
  } = props;
  const resetTheClock = useUserInfoUpdate();
  const [leases, setLeases] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;
  const statusList = [
    { status: "Set to Approved" },
    { status: "Set to Pending" },
    { status: "Set to Late" },
  ];

  const [show, setOpen1] = useState(false);
  const open1 = () => setOpen1(true);
  const handleClose1 = () => {
    console.log("the close ran")
    setOpen1(false);
  };
  const [paymentStatus, setPaymentStatus] = useState("Payment");
  const [entry, setentry] = useState("");
  const [enable, setEnable] = useState(false);
  const context = useUserInfo();
  const pathId = context.params.get("l");
  const [notes, setNotes] = useState("Add new note here");
  const [editingNote, setEditingNote] = useState(false);
  const [editingNote2, setEditingNote2] = useState(false);
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
  const [amount, setamount] = useState("");
  const [dateerror, setdateerror] = useState(false);
  const [timeerror, settimeerror] = useState(false);
  const [amounterror, setamounterror] = useState(false);
  useEffect(() => {
    var assets;
    userId && context && context.userInfo && context.userInfo.deals
      ? (assets = context.userInfo.deals.filter(
        (lease) => lease.user_id == userId
      ))
      : console.log("");
    context && context.userInfo && context.userInfo.deals && setLeases(assets);
  }, [context.userInfo]);

  function phoneChange(e) {
    const re = /^[0-9\b]+$/;
    let d = isNaN(e) ? 1 : e;
    if (d === "" || re.test(d)) {
      setPhone(d);
    }
  }
  function firstNameChange(e) {
    const reg = /^([^0-9$%]*)$/;
    if (reg.test(e)) {
      setFirstName(e);
    }
  }

  function lastNameChange(e) {
    const reg = /^([^0-9$%]*)$/;
    if (reg.test(e)) {
      setLastName(e);
    }
  }

  const startEditing3 = () => {
    setEditingNote(true);
  };

  const addnote = () => {
    setEditingNote(false);

    if (
      notes == ""
    ) {
      window.alert("Please fill in everything!");
    } else {
      fetch(`${API}/addNote`, {
        method: "POST",
        mode: "cors",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${context.accessToken}`
        },
        body: JSON.stringify({
          id: pageUserInfo.lease_id,
          type: "lease",
          time_stamp: moment(),
          note: notes,
        }),
      })
        .then((res) => window.confirm("Update successful"))
        .then((itstime) => {
          resetTheClock()
          setNotes("Add new note here")
        });
    }
  };

  function deletenote(id) {
    if (
      window.confirm("Confirm delete note ?")
    ) {
      fetch(`${API}/deleteNote/${id}`, {
        method: "DELETE",
        mode: "cors",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${context.accessToken}`
        },
      })
        .then((res) => window.confirm("Update successful"))
        .then((itstime) => { resetTheClock() });
    }
  };

  function updateleasePaymentStatus() {

    var status
    if (paymentStatus == "Set to Approved") {
      status = "approved"
    }
    else if (paymentStatus == "Set to Pending") {
      status = "pending"
    }
    else if (paymentStatus == "Set to Late") {
      status = "late"
    }
    else {
      status = "delete"
    }
    fetch(`${API}/updateleasePaymentStatus`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${context.accessToken}`,
      },
      body: JSON.stringify({
        status: status,
        lease_id: entry.lease_id,
        transaction_id: entry.transaction_id
      }),
    })
      .then((res) => { resetTheClock() });

  };

  function updateVisibilityStatus() {

    var visibility
    if (paymentStatus == "Hide") {
      visibility = "hidden"
    }

    else if (paymentStatus == "Unhide") {
      visibility = "public"
    }

    fetch(`${API}/updateVisibilityStatus`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${context.accessToken}`,
      },
      body: JSON.stringify({
        visibility: visibility,
        lease_id: entry.lease_id,
        transaction_id: entry.transaction_id
      }),
    })
      .then((res) => { resetTheClock() });


  };

  function createCommintment() {
    // const isValidDate = (date) => /^(0[1-9]|[12][0-9]|3[01])- [A-Z][a-z]{3} -[0-9]{4}$/.test(date);

    if (date == "" || time == "" || amount == "") {
      date == "" ? setdateerror(true) : setdateerror(false)
      time == "" ? settimeerror(true) : settimeerror(false)
      amount == "" ? setamounterror(true) : setamounterror(false)
      window.alert("Please enter the correct values")
    }
    else {
      setdateerror(false)
      settimeerror(false)
      setamounterror(false)
      handleClose2()

      fetch(`${API}/createLeasePaymentEntry`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${context.accessToken}`,
        },
        body: JSON.stringify({
          lease_id: pageUserInfo.lease_id,
          title: `commitment for ${moment(date).format("YYYY-MM-DD")} at ${time}`,
          amount: amount,
          status: "processing",
          user_id: pageUserInfo.user_id,
          payment_date: moment(date).format("YYYY-MM-DD")
        }),
      })
        .then((res) => { resetTheClock() });

    }

  }

  const handleClick = (event, payment) => {
    setAnchorEl(event.currentTarget);
    setentry(payment);
  };

  const handleClick2 = (event, payment) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  function selectStatus(stat) {
    setPaymentStatus(stat);
    setAnchorEl(null);
  }

  // console.log(pageUserInfo, " jkv jv");
  return (
    <div className="customerInfo">


      {/* ////// new designs for leasepayment system */}
      <div className="outerbox">
        <div className="buttons">
          <div className="titlebutton">
            + New Note Entry
          </div>
          <div className="titlebutton" type="button" aria-describedby={id2} onClick={(event) => handleClick2(event)}>
            + New Commitment
          </div>
        </div>

        {leasePaymenthistory.map((payment, i) => (
          <div className="entry">
            <div className="side1">
              <div className="side1title">
                <div className="side1titletext">
                  {moment(payment.created_date).format('MMM DD YYYY')}
                </div>
              </div>
              <div className="side1text">
                {
                  payment.title.includes("commitment") ?
                    `$${payment.amount} ${payment.title}` :
                    `$${payment.amount} ${payment.title} for ${moment(payment.payment_date).format('DD MMM YYYY')}`
                }
              </div>
            </div>
            <div className="side2">
              <div className="side2text"> {payment.status}</div>
              <div type="button" aria-describedby={id} onClick={(event) => handleClick(event, payment)} >
                <img src={vector} alt=""></img>
              </div>
            </div>
          </div>
        ))}

      </div>


      {/* the popover for the status change */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="popup">

          {statusList.map((status, i) => (
            <div
              className="popupitem"
              onClick={() => {
                selectStatus(status.status),
                  setOpen1(true)
              }}
              key={i}
            >
              {status.status}
            </div>
          ))}

          {entry && entry.title.includes("commitment") && <div
            className="popupitem"
            onClick={() => {
              entry.visibility == "hidden" ? selectStatus("Unhide") : selectStatus("Hide"),
                setOpen1(true)
            }}
          >
            {entry.visibility == "hidden" ? `Unhide` : `Hide`}
          </div>}

          <div
            className="popupitem"
            onClick={() => {
              selectStatus("Delete"),
                setOpen1(true)
            }}
            style={{ color: "#D38282" }}
          >
            Delete
          </div>

        </div>
      </Popover>

      {/* this popups for the leasepayment status */}

      {/* this popups for the +new commitment */}
      <Popover
        id={id}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="npopup">
          <div className="top">
            <div className="toptext">
              + New Commitment
            </div>
          </div>
          <div className="tline">
          </div>

          <div className="middle">
            <div className="middlebox">
              <div className={dateerror ? "middleboxtoperror" : "middleboxtop"}>
                Date
              </div>
              <input
                className={dateerror ? "middleboxdataerror" : "middleboxdata"}
                placeholder={"DD MMM YYYY"}
                onChange={(e) => setdate(e.target.value)}
                value={date}
              />
            </div>

            <div className="line"></div>

            <div className="middlebox">
              <div className={timeerror ? "middleboxtoperror" : "middleboxtop"}>
                Time
              </div>
              <input
                className={timeerror ? "middleboxdataerror" : "middleboxdata"}
                placeholder={"HH:MM PM"}
                onChange={(e) => settime(e.target.value)}
                value={time}
              />
            </div>

            <div className="line"></div>

            <div className="middlebox">
              <div className={amounterror ? "middleboxtoperror" : "middleboxtop"}>
                Amount
              </div>
              <input
                className={amounterror ? "middleboxdataerror" : "middleboxdata"}
                placeholder={"###.##"}
                onChange={(e) => setamount(e.target.value)}
                value={amount}
              />
            </div>
          </div>
          <div className="tline">
          </div>

          <div className="bottom">
            <div className="bottombuttons" type="button" onClick={(event) => handleClose2(event)}>
              Cancel
            </div>
            <div className="bottombuttons" onClick={() => createCommintment()}>
              Save
            </div>
          </div>

        </div>
      </Popover>
      {/* this popups for the +new commitment */}

      <Dialog open={show} onBackdropClick={handleClose1}>

        <div className="paymentdialog">
          <div className="head">
            {entry && paymentStatus && <div className="headingtext">

              {(entry.title.includes("commitment") ?
                (paymentStatus == "Delete" ? `Delete Commitment` : (paymentStatus == "Hide" ? `Hide Commitment` : `Confirm Commitment Status`))
                :
                (paymentStatus == "Delete" ? `Delete Payment` : `Confirm Payment Status`)
              )}

            </div>}
          </div>

          <div className="body">
            <div className="entry">
              <div className="side1">
                <div className="side1title">
                  <div className="side1titletext">
                    {moment(entry.created_date).format('MMM DD YYYY')}
                  </div>
                </div>
                <div className="side1text">
                  {entry.title}
                </div>
              </div>
              <div className="side2">
                <div className="side2text">{entry.status}</div>
                <div>
                  <img src={vector} alt=""></img>
                </div>
              </div>
            </div>
            <div className="buttons">
              <div className="cancel">
                <div className="canceltext" type="button" onClick={handleClose1}>
                  Cancel
                </div>
              </div>
              {paymentStatus != "Delete" ?
                (paymentStatus == 'Hide' || paymentStatus == 'Unhide') ?
                  <div className="set">
                    <div className="settext" onClick={() => { handleClose1(), updateVisibilityStatus() }}>
                      {paymentStatus}
                    </div>
                  </div>
                  :
                  <div className="set">
                    <div className="settext" onClick={() => { handleClose1(), updateleasePaymentStatus() }}>
                      {paymentStatus}
                    </div>
                  </div>

                :
                <div className="delete">
                  <div className="deletetext" onClick={() => { handleClose1(), updateleasePaymentStatus() }}>
                    {paymentStatus}
                  </div>
                </div>
              }
            </div>
          </div>

        </div>

      </Dialog>




      <div className="noteInfo">
        <div className="title">Notes on file</div>

        {note && (note.map((row, i) => (<div className="notes">
          <div key={i}>{row.notes}</div>
          <div className="button">
            <button
              style={{ marginTop: "0px" }}
              id={row.note_id}
              onClick={() => deletenote(row.note_id)}
              className="saveButton">
              DELETE
            </button>
          </div>
        </div>)))}

        <div className="notes">
          <input
            placeholder={"Add new note here"}
            disabled={!editingNote}
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          ></input>
          {editingNote ? (
            <div className="button">
              <button onClick={() => addnote()} className="saveButton">
                SAVE
              </button>
            </div>
          ) : (
            <div className="button">
              <button onClick={() => startEditing3()} className="editButton">
                EDIT
              </button>
            </div>
          )}
        </div>
      </div>






      <div className="contactInfo">
        <div className="title">
          <h1>User info</h1>
        </div>
        <div className="inputs">
          <div className="nameInput">
            <input
              placeholder={
                pageUserInfo.first_name == "NaN"
                  ? "First Name"
                  : pageUserInfo.first_name
              }
              disabled={!editing}
              style={{ width: "100%" }}
              onChange={(e) => firstNameChange(e.target.value)}
              value={first_name}
            ></input>
            <input
              placeholder={
                pageUserInfo.last_name == "NaN"
                  ? "Last Name"
                  : pageUserInfo.last_name
              }
              disabled={!editing}
              style={{ width: "100%" }}
              onChange={(e) => lastNameChange(e.target.value)}
              value={last_name}
            ></input>
          </div>
          <input
            placeholder={
              pageUserInfo.email == "NaN" ? "Email" : pageUserInfo.email
            }
            disabled={!editing}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          ></input>

          <input
            placeholder={
              pageUserInfo.phone == "NaN" ? "Phone" : pageUserInfo.phone
            }
            disabled={!editing}
            onChange={(e) => phoneChange(e.target.value)}
            pattern="[0-9]{3}"
            value={phone}
          ></input>

          <input
            placeholder={
              (pageUserInfo.street_address == "NaN" || pageUserInfo.street_address == null)
                ? "Street Address"
                : pageUserInfo.street_address
            }
            disabled={!editing}
            onChange={(e) => setStreet_address(e.target.value)}
            value={street_address}
          ></input>

          <input
            placeholder={
              (pageUserInfo.city == "NaN" || pageUserInfo.city == null)
                ? "City"
                : pageUserInfo.city
            }
            disabled={!editing}
            onChange={(e) => setCity(e.target.value)}
            value={city}
          ></input>

          <input
            placeholder={
              (pageUserInfo.province == "NaN" || pageUserInfo.province == null )
                ? "Province"
                : pageUserInfo.province
            }
            disabled={!editing}
            onChange={(e) => setProvince(e.target.value)}
            value={province}
          ></input>

          <input
            placeholder={
              (pageUserInfo.country == "NaN" || pageUserInfo.country == null )
                ? "Country"
                : pageUserInfo.country
            }
            disabled={!editing}
            onChange={(e) => setCountry(e.target.value)}
            value={country}
          ></input>

          <input
            placeholder={
              pageUserInfo.postal_code == "NaN"
                ? "Postal Code"
                : pageUserInfo.postal_code
            }
            disabled={!editing}
            maxLength={7}
            onChange={(e) => setPostalCode(e.target.value)}
            value={postal_code}
          ></input>

          <div className="refCodeUser">
            <input placeholder={pageUserInfo.referral_link} disabled />
            <button
              onClick={() =>
                navigator.clipboard.writeText(pageUserInfo.referral_link)
              }
            >
              COPY
            </button>
          </div>
          <div className="refCodeUser">
            <input placeholder={pageUserInfo.user_id} disabled />
            <button
              onClick={() =>
                navigator.clipboard.writeText(pageUserInfo.user_id)
              }
            >
              COPY
            </button>
          </div>
        </div>

        {editing ? (
          <div className="button">
            <button onClick={changeUserInfo} className="saveButton">
              SAVE
            </button>
          </div>
        ) : (
          <div className="button">
            <button onClick={() => startEditing()} className="editButton">
              EDIT
            </button>
          </div>
        )}
      </div>

      {(context && context.userInfo && context.userInfo.insurance && insurance) ? (<div className="contactInfo">
        <div className="title">
          <h1>Post Deal Info</h1>
        </div>
        <div className="inputs">
          <input
            placeholder={
              insurance.license_plate == "NaN" ? "License Plate" : insurance.license_plate
            }
            disabled={!editing3}
            onChange={(e) => setlicensePlate(e.target.value)}
            value={license_plate}
          ></input>
          <input
            placeholder={
              insurance.registration == "NaN" ? "Registration" : insurance.registration
            }
            disabled={!editing3}
            onChange={(e) => setregistration(e.target.value)}
            value={registration}
          ></input>
          <input
            placeholder={
              insurance.registration_province == null ? "Registration Province" : insurance.registration_province
            }
            disabled={!editing3}
            onChange={(e) => setRegistrationProvince(e.target.value)}
            value={registration_province}
          ></input>
        </div>
        <div className="button">
          <a
            className="viewContract"
            href={`https://econommi.sharepoint.com/sites/operations/shared%20documents/sales%20and%20support/fapp/users/${pageInfo.email}/account`}
            target="_blanck"
          >
            <button>View Insurance Documents</button>
          </a>
        </div>
        {editing3 ? (
          <div className="button">
            <button onClick={changeInsuranceInfo} className="saveButton">
              SAVE
            </button>
          </div>
        ) : (
          <div className="button">
            <button onClick={() => startEditing4()} className="saveButton">
              EDIT
            </button>
          </div>
        )}
      </div>) : <div></div>}

      {(context && context.userInfo) ? (<div className="contactInfo">
        <div className="title">
          <h1>Tracker ID</h1>
        </div>
        <div className="inputs">
          <input
            placeholder={
              pageUserInfo.tracker_number == "NaN" ? "Tracker Number" : pageUserInfo.tracker_number
            }
            disabled={!editing4}
            onChange={(e) => setTrackerNumber(e.target.value)}
            value={tracker_number}
          ></input>
        </div>
        {editing4 ? (
          <div className="button">
            <button onClick={updateTrackerNumber} className="saveButton">
              SAVE
            </button>
          </div>
        ) : (
          <div className="button">
            <button onClick={() => startEditing5()} className="editButton">
              EDIT
            </button>
          </div>
        )}
      </div>) : <div></div>}


      <div className="transactionHistory">
        <div className="title">
          {/* <h1>Transaction history</h1> */}
          <h1>Car history</h1>
        </div>
        <div className="content">
          {payments.concat(history).map((payment, i) => (
            <div className="contentCard"
              key={i}>
              <div className="date">
                {payment.date
                  ? moment(payment.date).format("DD/MM/YY")
                  : moment(payment.datetime).format("DD/MM/YY")}
              </div>
              <div className="name">
                {payment.verb
                  ? payment.verb
                  : payment.amount / 100 > pageInfo.monthly_payment
                    ? Math.round(pageInfo.monthly_payment)
                    : payment.amount / 100 < pageInfo.monthly_payment
                      ? "Under payment received"
                      : "Full payment received - " +
                      moment(payment.date).format("MM/YY")}
              </div>
              <div className="price">
                {payment.adverb
                  ? payment.adverb == "NaN"
                    ? " "
                    : "$" + (payment.adverb / 100).toFixed(2)
                  : "$" + (payment.amount / 100).toFixed(2)}
              </div>
            </div>
          ))}
        </div>
        <div className="disclaimer">
          Transaction history may be incorrect. For most updated data, visit
          Xero.{" "}
        </div>
      </div>
      <div className="customerAsset">
        <div className="title">
          <h1>Customer Assets</h1>
        </div>
        <div className="content">
          {leases &&
            leases.map((lease, i) => (
              <Link
                style={{ textDecoration: "None", color: "black" }}
                to={{
                  pathname: `/User/?l=${lease.lease_id}`,
                }}
                key={i}
              >
                <div
                  className={`assetCard`}
                  style={{
                    backgroundColor:
                      pageInfo.lease_id == leases[i].lease_id
                        ? "white"
                        : "#eeeeee",
                  }}
                >
                  <div className="carName">
                    {lease.year + " " + lease.make + " " + lease.model}
                  </div>
                  <div className="status">{lease.leased_status}</div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
