import React, { useState, useEffect } from "react";
import "./UserName.scss";
import polygon from "../../../assets/Polygon.png";
import plus from "../../../assets/Plus.png";
import Grid from "@material-ui/core/Grid";
import SellTokens from "./SellTokens";
//import 'react-virtualized/styles.css';
import moment from "moment";
const API = process.env.REACT_APP_API_URL;

function UserInfo({ history, assets, customer, accessToken, resetTheClock, note }) {
  const [encore, setStatus] = useState();
  const [interac_email, setInteracEmail] = useState("");
  const [ethereum_wallet, setEthereumWallet] = useState("");
  const [editing3, setEditing3] = useState(false);
  const [historyEntry, setHistoryEntry] = useState();
  const [bitcoin_wallet, setBitcoinWallet] = useState("");
  const [editing, setEditing] = useState(false);
  const [editing2, setEditing2] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("");
  const [street_address, setStreet_address] = useState("");
  const [city, setCity] = useState("");
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState();
  const [email, setEmail] = useState();
  const [notes, setNotes] = useState("Add new note here");
  const [editingNote, setEditingNote] = useState(false);

  const changeDetails = () => {
    if (!interac_email.includes("@") || !interac_email.includes(".com")) {
      window.alert("Please input the right email");
    } else {
      setEditing2(false);

      fetch(`${API}/updateUserDetails`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          user_id: customer.user_id,
          ethereum_wallet:
            ethereum_wallet === "" ? customer.ethereum_wallet : ethereum_wallet,
          interac_email:
            interac_email === "" ? customer.interac_email : interac_email,
          bitcoin_wallet:
            bitcoin_wallet === "" ? customer.bitcoin_wallet : bitcoin_wallet,
        }),
      }).then(window.confirm("Change sucessful."), resetTheClock());
    }
  };

  const changeUserInfo = () => {
    setEditing(false);
    fetch(`${API}/updateUserCore`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        first_name: first_name === "" ? customer.first_name : first_name,
        last_name: last_name === "" ? customer.last_name : last_name,
        phone: phone === "" ? customer.phone : phone,
        street_address: street_address === "" ? customer.street_address : street_address,
        city: city === "" ? customer.city : city,
        province: province === "" ? customer.province : province,
        country: country === "" ? customer.country : country,
        postal_code: postal_code === "" ? customer.postal_code : postal_code,
        user_id: customer.user_id,
      }),
    }).then(window.confirm("Change sucessful."), resetTheClock());
  };

  const addHistoryEntry = () => {
    setEditing3(false);
    fetch(`${API}/addHistoryEntry`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        verb: historyEntry,
        lease_id: " ",
        user_id: customer.user_id,
      }),
    })
      .then((res) => window.confirm("Update successful"))
      .then((res) => setHistoryEntry(""))
      .then((res) => window.location.reload());
  };

  const startEditing = () => {
    setEditing(true);
    customer.first_name != "NaN" && setFirstName(customer.first_name);
    customer.last_name != "NaN" && setLastName(customer.last_name);
    customer.phone != "NaN" && setPhone(customer.phone);
    customer.email != "NaN" && setEmail(customer.email);
    customer.street_address != "NaN" && setStreet_address(customer.street_address);
    customer.city != "NaN" && setCity(customer.city);
    customer.postal_code != "NaN" && setPostalCode(customer.postal_code);
    customer.province != "NaN" && setProvince(customer.province);
    customer.country != "NaN" && setCountry(customer.country);
  };

  const startEditing2 = () => {
    setEditing2(true);
    customer.interac_email != "NaN" && setInteracEmail(customer.interac_email);
    customer.ethereum_wallet != "NaN" &&
      setEthereumWallet(customer.ethereum_wallet);
    customer.bitcoin_wallet != "NaN" &&
      setBitcoinWallet(customer.bitcoin_wallet);
  };
  const startEditing3 = () => {
    historyEntry == " "
      ? setHistoryEntry("History entry empty")
      : setEditing3(true);
  };

  function openSalePopUp(asset) {
    setToken(asset);
    setOpen(true);
  }

  function closeSalePopUp() {
    setOpen(false);
  }

  function phoneChange(e) {
    const re = /^[0-9\b]+$/;
    let d = isNaN(e.target.value) ? 1 : e.target.value;
    if (d === "" || re.test(d)) {
      setPhone(d);
    }
  }

  function postalChange(e) {
    if (e.target.value.length <= 6) {
      setPostalCode(e.target.value);
    }
  }

  function firstNameChange(e) {
    const reg = /^([^0-9$%]*)$/;
    if (reg.test(e)) {
      setFirstName(e);
    }
  }

  function lastNameChange(e) {
    const reg = /^([^0-9$%]*)$/;
    if (reg.test(e)) {
      setLastName(e);
    }
  }

  const startEditing4 = () => {
    setEditingNote(true);
  };

  const addnote = () => {
    setEditingNote(false);
    // console.log(notes,"jjkbjhgj")
    if (
      notes == ""
    ) {
      window.alert("Please fill in everything!");
    } else {
      fetch(`${API}/addNote`, {
        method: "POST",
        mode: "cors",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          id: customer.user_id,
          type: "invest",
          time_stamp: moment(),
          note: notes,
        }),
      })
        .then((res) => window.confirm("Update successful"))
        .then((itstime) => {
          resetTheClock()
          setNotes("Add new note here")
        });
    }
  };

  function deletenote(id) {
    if (
      window.confirm("Confirm delete note ?")
    ) {
      fetch(`${API}/deleteNote/${id}`, {
        method: "DELETE",
        mode: "cors",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      })
        .then((res) => window.confirm("Update successful"))
        .then((itstime) => { resetTheClock() });
    }
  };

  return (
    <div className="userInfo">
      {/* User Info */}
      <div className="noteInfo">
        <div className="title">Notes on file</div>

        {note && (note.map((row, i) => (<div className="notes">
          <div>{row.notes}</div>
          <div className="button">
            <button
              style={{ marginTop: "0px" }}
              id={row.note_id}
              onClick={() => deletenote(row.note_id)}
              className="saveButton">
              DELETE
            </button>
          </div>
        </div>)))}

        <div className="notes">
          <input
            placeholder={"Add new note here"}
            disabled={!editingNote}
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          ></input>
          {editingNote ? (
            <div className="button">
              <button onClick={() => addnote()} className="saveButton">
                SAVE
              </button>
            </div>
          ) : (
            <div className="button">
              <button onClick={() => startEditing4()} className="editButton">
                EDIT
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="userInfoCard">
        <div className="title">
          <h1>User Info</h1>
        </div>
        <div className="inputs">
          <div className="name">
            <input
              placeholder={
                customer.first_name == "NaN"
                  ? "First Name"
                  : customer.first_name
              }
              disabled={!editing}
              onChange={(e) => firstNameChange(e.target.value)}
              value={first_name}
            />
            <input
              placeholder={
                customer.last_name == "NaN" ? "Last Name" : customer.last_name
              }
              disabled={!editing}
              onChange={(e) => lastNameChange(e.target.value)}
              value={last_name}
            />
          </div>
          <input
            placeholder={customer.email == "NaN" ? "Email" : customer.email}
            disabled={!editing}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <input
            placeholder={
              customer.phone == "NaN" ? "Phone Number" : customer.phone
            }
            disabled={!editing}
            onChange={(e) => phoneChange(e)}
            value={phone}
          ></input>

          <input
            placeholder={
             ( customer.street_address == "NaN" ||  customer.street_address == null)? "Street_address" : customer.street_address
            }
            disabled={!editing}
            onChange={(e) => setStreet_address(e.target.value)}
            value={street_address}
          />

          <input
            placeholder={
              (customer.city == "NaN" ||  customer.city == null)
                ? "City"
                : customer.city
            }
            disabled={!editing}
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />

          <input
            placeholder={
              (customer.province == "NaN" ||  customer.province == null)
                ? "Province"
                : customer.province
            }
            disabled={!editing}
            onChange={(e) => setProvince(e.target.value)}
            value={province}
          />

          <input
            placeholder={
              customer.country == "NaN"
                ? "Country"
                : customer.country
            }
            disabled={!editing}
            onChange={(e) => setCountry(e.target.value)}
            value={country}
          />

          <input
            placeholder={
              customer.postal_code == "NaN"
                ? "Postal Code"
                : customer.postal_code
            }
            disabled={!editing}
            onChange={(e) => postalChange(e)}
            value={postal_code}
          />

          <div className="refCode">
            <input placeholder={customer.referral_link} disabled />
            <button
              onClick={() =>
                navigator.clipboard.writeText(customer.referral_link)
              }
            >
              COPY
            </button>
          </div>
          <div className="refCode">
            <input placeholder={customer.user_id} disabled />
            <button
              onClick={() => navigator.clipboard.writeText(customer.user_id)}
            >
              COPY
            </button>
          </div>
        </div>
        <div className="button">
          {editing ? (
            <button className="saveButton" onClick={changeUserInfo}>
              SAVE
            </button>
          ) : (
            <button className="editButton" onClick={startEditing}>
              EDIT
            </button>
          )}
        </div>
      </div>

      {/* Payment Info */}
      <div className="paymentInfo">
        <div className="title">
          <h1>Payment Info</h1>
        </div>
        <div className="inputs">
          <div className="email">
            <div className="inputTitle">Email for Interac</div>
            <div className="input">
              <input
                disabled={!editing2}
                onChange={(e) => setInteracEmail(e.target.value)}
                placeholder={
                  customer.interac_email != "NaN"
                    ? customer.interac_email
                    : "Please set your interac email"
                }
                value={interac_email}
              />
              <button
                onClick={() =>
                  navigator.clipboard.writeText(customer.interac_email)
                }
              >
                COPY
              </button>
            </div>
          </div>
          <div className="address">
            <div className="inputTitle">Bitcoin Wallet Address</div>
            <div className="input">
              <input
                disabled={!editing2}
                onChange={(e) => setBitcoinWallet(e.target.value)}
                placeholder={
                  customer.bitcoin_wallet != "NaN"
                    ? customer.bitcoin_wallet
                    : // : "Please set your bitcoin wallet"
                    "Address"
                }
                value={bitcoin_wallet != "NaN" && bitcoin_wallet}
              />
              <button
                onClick={() =>
                  navigator.clipboard.writeText(customer.bitcoin_wallet)
                }
              >
                COPY
              </button>
            </div>
          </div>
          <div className="wallet">
            <div className="inputTitle">
              Ethereum / BSC / Polygon Wallet Address
            </div>
            <div className="input">
              <input
                disabled={!editing2}
                onChange={(e) => setEthereumWallet(e.target.value)}
                placeholder={
                  customer.ethereum_wallet != "NaN"
                    ? customer.ethereum_wallet
                    : "Please set your ethereum wallet"
                }
                value={ethereum_wallet != "NaN" && ethereum_wallet}
              />
              <button
                onClick={() =>
                  navigator.clipboard.writeText(customer.ethereum_wallet)
                }
              >
                COPY
              </button>
            </div>
          </div>
        </div>
        <div className="button">
          {editing2 ? (
            <button className="saveButton" onClick={changeDetails}>
              SAVE
            </button>
          ) : (
            <button className="editButton" onClick={startEditing2}>
              EDIT
            </button>
          )}
        </div>
      </div>

      {/* History */}
      <div className="history">
        <div className="title">
          <h1>Platform History</h1>
          {/* <h1>History</h1> */}
        </div>
        <div className="content">
          {/* {console.log("history", history)} */}
          {history
            ? history.map((row) => (
              <div className="contentCard">
                <div className="date">
                  {moment(row.datetime).format("YYYY/MM/DD")}
                </div>
                <div className="name">
                  {row.verb === "sale" ? (
                    <div>
                      <b style={{ color: "rgb(17, 23, 177)" }}>Token </b>Sale-
                      (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "token_buy_out" ? (
                    <div>
                      <b style={{ color: "rgb(17, 23, 177)" }}>Token </b>
                      Buyout- (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "purchase" ? (
                    <div>
                      <b style={{ color: "rgb(17, 23, 177)" }}>Token </b>
                      Purchase- (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "encore_purchase" ? (
                    <div>
                      <b>encore</b> purchase- (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "wallet_purchase" ? (
                    <div>
                      <b style={{ color: "rgb(4, 104, 26)" }}>Wallet</b>{" "}
                      purchase- (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "peer_sale" ? (
                    <div>
                      <b>Community</b> sale- (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "peer_wallet_purchase" ? (
                    <div>
                      <b>Community</b>{" "}
                      <b style={{ color: "rgb(4, 104, 26)" }}>Wallet </b>
                      purchase- (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "peer_purchase" ? (
                    <div>
                      <b>Community</b> purchase- (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "boost_payout" ? (
                    <div>
                      <b
                        style={{
                          color:
                            customer.boost_status === "GOLD"
                              ? "#D4AF37"
                              : customer.boost_status === "SILVER"
                                ? "#A2ABBF"
                                : customer.boost_status === "BRONZE"
                                  ? "#CD7F32"
                                  : "black",
                        }}
                      >
                        Boost
                      </b>{" "}
                      payout
                    </div>
                  ) : row.verb === "wallet_top_up" ? (
                    <div>
                      <b style={{ color: "rgb(4, 104, 26)" }}>Wallet</b> top
                      up- ${row.adverb / 100}
                    </div>
                  ) : row.verb === "loan" ? (
                    <div>
                      <b style={{ color: "rgb(4, 104, 26)" }}>Loan</b> payout-{" "}
                    </div>
                  ) : row.verb === "loan_payment" ? (
                    <div>
                      <b style={{ color: "rgb(4, 104, 26)" }}>Loan</b>{" "}
                      payment-{" "}
                    </div>
                  ) : row.verb === "loan_finished" ? (
                    <div>
                      <b style={{ color: "rgb(4, 104, 26)" }}>Loan</b>{" "}
                      finished-{" "}
                    </div>
                  ) : row.verb === "econ_wallet_purchase" ? (
                    <div>
                      <b
                        style={{
                          color: "rgb(108, 16, 109)",
                          fontWeight: "700",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        ECON
                      </b>{" "}
                      <b style={{ color: "rgb(4, 104, 26)" }}>wallet </b>
                      purchase- ({row.adverb} @
                      {(row.result / row.adverb).toFixed(2)}￠)
                    </div>
                  ) : row.verb === "encore_econ_purchase" ? (
                    <div>
                      <b>encore </b>{" "}
                      <b
                        style={{
                          color: "rgb(108, 16, 109)",
                          fontWeight: "700",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        ECON{" "}
                      </b>
                      purchase- ({parseFloat(row.adverb).toFixed(2)} @
                      {((row.result / row.adverb) * 100).toFixed(2)}￠)
                    </div>
                  ) : row.verb === "econ_purchase" ? (
                    <div>
                      <b
                        style={{
                          color: "rgb(108, 16, 109)",
                          fontWeight: "700",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        ECON{" "}
                      </b>
                      purchase- ({row.adverb} @
                      {(row.result / row.adverb).toFixed(2)}￠)
                    </div>
                  ) : row.verb === "econ_withdraw" ? (
                    <div>
                      <b
                        style={{
                          color: "rgb(108, 16, 109)",
                          fontWeight: "700",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        ECON{" "}
                      </b>
                      withdraw- ({row.adverb} @
                      {(row.result / row.adverb).toFixed(2)}￠)
                    </div>
                  ) : row.verb === "interac_top_up" ? (
                    <div>
                      Interac{" "}
                      <b style={{ color: "rgb(4, 104, 26)" }}>Wallet</b> top
                      up- ${Math.round(row.adverb / 100)}
                    </div>
                  ) : row.verb === "membership_change" ? (
                    <div>
                      You changed membership from{" "}
                      <b
                        style={{
                          color:
                            row.subject === "GOLD"
                              ? "#D4AF37"
                              : row.subject === "SILVER"
                                ? "#A2ABBF"
                                : row.subject === "BRONZE"
                                  ? "#CD7F32"
                                  : "black",
                        }}
                      >
                        {row.subject}
                      </b>{" "}
                      to{" "}
                      <b
                        style={{
                          color:
                            row.result === "GOLD"
                              ? "#D4AF37"
                              : row.result === "SILVER"
                                ? "#A2ABBF"
                                : row.result === "BRONZE"
                                  ? "#CD7F32"
                                  : "black",
                        }}
                      >
                        {row.result}
                      </b>
                    </div>
                  ) : row.verb === "up_for_sale" ? (
                    <div>
                      <b style={{ color: "rgb(17, 23, 177)" }}>Token</b> up
                      for sale- (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "taken_down" ? (
                    <div>
                      <b style={{ color: "rgb(17, 23, 177)" }}>Token</b> taken
                      down from sale- (
                      {row.adverb +
                        " @ $" +
                        (row.result / row.adverb / 100).toFixed(2)}
                      )
                    </div>
                  ) : row.verb === "referral" ? (
                    <div>
                      <b style={{ color: "rgb(4, 104, 26)" }}>Referral</b>{" "}
                      credit- ${row.result / 100}
                    </div>
                  ) : (
                    row.verb
                  )}
                </div>
                {row.verb != "membership_change" &&
                  row.verb != "referral" &&
                  row.verb != "up_for_sale" &&
                  row.verb != "taken_down" && (
                    <div className="price">
                      {row.verb === "sale"
                        ? `+${(row.result / 100).toFixed(2)}`
                        : row.verb === "token_buy_out"
                          ? `+$${(row.result / 100).toFixed(2)}`
                          : row.verb === "purchase"
                            ? `-$${(row.result / 100).toFixed(2)}`
                            : row.verb === "encore_purchase"
                              ? `-$${(row.result / 100).toFixed(2)}`
                              : row.verb === "wallet_purchase"
                                ? `-$${(row.result / 100).toFixed(2)}`
                                : row.verb === "peer_sale"
                                  ? `+$${(row.result / 100).toFixed(2)}`
                                  : row.verb === "peer_wallet_purchase"
                                    ? `-$${(row.result / 100).toFixed(2)}`
                                    : row.verb === "peer_purchase"
                                      ? `-$${(row.result / 100).toFixed(2)}`
                                      : row.verb === "boost_payout"
                                        ? `+$${(row.adverb / 100).toFixed(2)}`
                                        : row.verb === "wallet_top_up"
                                          ? `+$${(row.result / 100).toFixed(2)}`
                                          : row.verb === "loan"
                                            ? `+$${row.result}`
                                            : row.verb === "loan_payment"
                                              ? `-$${row.result}`
                                              : row.verb === "econ_wallet_purchase"
                                                ? `-$${(row.result / 100).toFixed(2)}`
                                                : row.verb === "encore_econ_purchase"
                                                  ? `-$${((row.result * 100) / 100).toFixed(2)}`
                                                  : row.verb === "econ_purchase"
                                                    ? `-$${(row.result / 100).toFixed(2)}`
                                                    : row.verb === "econ_withdraw"
                                                      ? `-$${(row.result / 100).toFixed(2)}`
                                                      : row.verb === "interac_top_up"
                                                        ? `+$${(row.adverb / 100).toFixed(2)}`
                                                        : row.verb === "referral"
                                                          ? `+$${((row.result * 100) / 100).toFixed(2)}`
                                                          : row.result == "NaN"
                                                            ? " "
                                                            : row.result}
                    </div>
                  )}
              </div>
            ))
            : "This user has no history"}
        </div>
        {/* <input
          className="contentCard"
          style={{
            marginTop: "10px",
            padding: "7px",
            borderRadius: "10px",
            width: "100%",
          }}
          disabled={!editing3}
          placeholder="Enter the new history entry here"
          onChange={(e) => setHistoryEntry(e.target.value)}
          value={historyEntry}
        ></input>
        <div className="buttons">
          {editing3 ? (
            <button className="saveButton" onClick={addHistoryEntry}>
              SAVE
            </button>
          ) : (
            <button className="editButton" onClick={() => startEditing3()}>
              EDIT
            </button>
          )}
        </div> */}
      </div>

      {/* Asset Portfolio */}
      <div className="assetPortfolio">
        <div className="title">
          <h1>Asset Portfolio</h1>
        </div>
        <div className="content">
          {assets &&
            assets.map((asset) => (
              <div className="assetCard">
                <div className="contentCard">
                  <div className="date">
                    ${(asset.token_price / 100).toFixed(2)}
                  </div>
                  <div className="car">
                    {asset.year + " " + asset.make + " " + asset.model}
                  </div>
                  <div className="qty">{asset.count}</div>
                  <button className="btn" onClick={() => openSalePopUp(asset)}>
                    Sell
                  </button>
                </div>
                <button onClick={() => openSalePopUp(asset)}>Sell</button>
              </div>
            ))}
        </div>
      </div>
      {resetTheClock && (
        <SellTokens
          open={open}
          lease={token}
          closeSalePopUp={closeSalePopUp}
          resetTheClock={resetTheClock}
          user_id={customer.user_id}
          accessToken={accessToken}
        />
      )}
    </div>
  );
}

export default UserInfo;
